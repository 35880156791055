<div mat-dialog-title>
  <h2>
    {{ (recordId || isOurProfile || isOurProfileVl ? 'labProfile.update' : 'labProfile.addNew')|translate }}
    {{"labProfile.educationalCenter"|translate}}
    {{( data.addBranch ? 'labProfile.branch' : '')|translate }}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <form [formGroup]="formGroup">

      <div class="fields-group">
        <!-- <div [hidden]="!hasPermission('SuperAdmin') || isOurProfileVl">
          <div class="fields-group" [hidden]="formGroup.controls.type.value === 'BRANCH' || disableLabType">
            <mat-slide-toggle formControlName="isHospitalClinic" [labelPosition]="'before'"
              (change)="changeSlide($event)">
              <i>Is it a Hospital or a Clinic Lab?</i>
            </mat-slide-toggle>
          </div>
          <mat-form-field [hidden]='!formGroup.controls.isHospitalClinic.value'>
            <mat-label>Select Hospital / Clinic</mat-label>
            <mat-select disableOptionCentering formControlName="entityId"
              [required]='formGroup.controls.isHospitalClinic.value'>
              <mat-option [value]="item" *ngFor="let item of entityList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('entityId', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          </mat-form-field>
          <div [hidden]='disableLabType || formGroup.controls.isHospitalClinic.value'>
            <h3 class="fields-title">Type</h3>
            <div class="form-group">
              <div class="radio-selector">
                <mat-radio-group formControlName="type" class="row" [required]='!isOurProfileVl'
                  [disabled]='disableLabType'>
                  <mat-radio-button class="col-6" [value]="'MAIN_LAB'" [disabled]='data.addBranch || disableLabType'>
                    Main
                  </mat-radio-button>
                  <mat-radio-button class="col-6" [value]="'BRANCH'">Branch</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>

          <mat-form-field [hideRequiredMarker]='true'
            [hidden]='formGroup.controls.type.value !== "BRANCH" || data.addBranch'>
            <mat-label>Select main Lab</mat-label>
            <mat-select disableOptionCentering formControlName="mainLab" [disabled]='data.addBranch'
              [required]='formGroup.controls.type.value == "BRANCH"'>
              <mat-option [value]="item.id" *ngFor="let item of mainLabsList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('mainLab', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          </mat-form-field>
        </div> -->

        <mat-form-field>
          <mat-label>{{"labProfile.educationalCenterName"|translate}}</mat-label>
          <input matInput formControlName="Lab" maxlength="100">
          <mat-error *ngIf="hasError('Lab', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          <mat-error *ngIf="hasError('Lab', 'notUniqe')">{{"labProfile.labNameAlreadyExist"|translate}}</mat-error>
        </mat-form-field>
      </div>

      <div class="fields-group mb-5">
        <div class="upload-logo">
          <div class="image-upload">
            <label for="imageUpload">
              <input type='file' class="img-viewer" id="imageUpload" accept="image/jpeg, image/pjpeg, image/png"
                (change)="changeFileListener($event)">
              <div class="image-preview">
                <img [src]="logoSrc" *ngIf="logoSrc">
              </div>
              <span class="plus-icon"></span>
            </label>
          </div>
          <div>
            <label>{{ 'forms.Upload photo' | translate }}</label>
            <p>{{ 'forms.Maximumfilesize800KB' | translate }} <br>
              {{ 'forms.Werecommendimagedimensions' | translate }}</p>
          </div>
        </div>
        <p class="invalid-feedback mt-2 d-block" *ngIf="!logoSrc && isSubmitted">{{ 'forms.Required' | translate }}</p>
        <p class="invalid-feedback mt-2 d-block" *ngIf="fileTypeError">{{ 'forms.InvalidFile' | translate }}</p>
        <p class="invalid-feedback mt-2 d-block" *ngIf="fileSizeError">{{ 'forms.Max-files' | translate }}
          {{maxFileSize}} Mb</p>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">{{"labProfile.contactsInfo"|translate}}</h3>

        <div class="form-group">
          <ngx-intl-tel-input [customPlaceholder]="" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
            [maxLength]="50" [numberFormat]="PhoneNumberFormat.International" [phoneValidation]="true"
            [searchCountryField]="[SearchCountryField.All]" [searchCountryFlag]="true" [selectFirstCountry]="false"
            [selectedCountryISO]="selectedCountryISO" [separateDialCode]="true" formControlName="contactNumber"
            name="contactNumber">
          </ngx-intl-tel-input>
          <p class="has-error" *ngIf="
              formGroup.controls.contactNumber.errors &&
              formGroup.controls.contactNumber.errors.required && isSubmitted">{{ 'forms.Required' | translate }}</p>

          <p class="has-error" *ngIf="
              formGroup.controls.contactNumber.errors &&
              formGroup.controls.contactNumber.errors.validatePhoneNumber ">
            {{"labProfile.enterValidNumberAccordingSelectedCountry"|translate}}
          </p>
        </div>

        <mat-form-field>
          <mat-label>{{"general.emailAddress"|translate}}</mat-label>
          <input matInput formControlName="email" maxlength="50" (cdkAutofill)="autoFillInput('email')">
          <mat-error *ngIf="hasError('email', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          <mat-error *ngIf="hasError('email', 'invalid')">{{ 'forms.Invalid-Email' | translate }}</mat-error>
          <mat-error *ngIf="hasError('email', 'pattern')">{{ 'forms.Invalid-Email' | translate }}</mat-error>
        </mat-form-field>

          <mat-form-field>
            <mat-label>{{"forms.AllowedPlagiarismPercentage" | translate}} </mat-label>
            <input matInput formControlName="plagiarismThreshold" [required]="hasPermission('LabAdmin') || hasPermission('VLAdmin')" appOnlynumber>
            <mat-icon matSuffix> percent</mat-icon>
            <mat-error *ngIf="hasError('plagiarismThreshold', 'required')">{{ 'forms.Required' | translate }}</mat-error>
            <mat-error *ngIf="hasError('plagiarismThreshold', 'min')">
              {{"forms.minNumberIs"|translate:{num:1} }}
            </mat-error>
            <mat-error *ngIf="hasError('plagiarismThreshold', 'max')">
              {{"forms.maxNumberIs"|translate:{num:100} }}
            </mat-error>
          </mat-form-field>
       </div>

      <div class="fields-group">
        <h3 class="fields-title">{{"general.address"|translate}}</h3>

        <mat-form-field>
          <mat-label>{{"general.country"|translate}}</mat-label>
          <mat-select disableOptionCentering formControlName="country" (selectionChange)='getRegions(true)'>
            <mat-option [value]="item.id" *ngFor="let item of countryList">
              <span>{{item.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('country', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"general.regionGovernorateEmirate"|translate}}</mat-label>
          <mat-select disableOptionCentering formControlName="region" (selectionChange)='getCities(true)'>
            <mat-option [value]="item.id" *ngFor="let item of regionList">
              <span>{{item.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('region', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"general.city"|translate}}</mat-label>
          <mat-select disableOptionCentering formControlName="cityId">
            <mat-option [value]="item.id" *ngFor="let item of cityList">
              <span>{{item.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('cityId', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"general.streetAddress"|translate}}</mat-label>
          <textarea matInput formControlName="address" maxlength="500"></textarea>
          <mat-error *ngIf="hasError('address', 'required')">{{ 'forms.Required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">{{"general.manager"|translate}}</h3>

        <div class="row">
          <div class="col-sm-6">
            <mat-form-field>
              <mat-label>{{"general.firstName"|translate}}</mat-label>
              <input matInput formControlName="managerFirstName" maxlength="50">
              <mat-error *ngIf="hasError('managerFirstName', 'required')">{{ 'forms.Required' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field>
              <mat-label>{{"general.lastName"|translate}}</mat-label>
              <input matInput formControlName="managerLastName" maxlength="50">
              <mat-error *ngIf="hasError('managerLastName', 'required')">{{ 'forms.Required' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">{{"labProfile.slideCommunicationMethods"|translate}}
          <i class="sub-title">{{"labProfile.selectHowYouWillGetTheSlides"|translate}}</i>
        </h3>
        <div class="sets-conatiner" formArrayName="communicationMethod">
          <div class="set-item collapsed" [formGroupName]="i"
            *ngFor="let method of communicationMethods.controls; let i = index"
            [class.collapsed]='!communicationMethods.controls[i]["controls"].selectedMethod.value'>
            <div class="set-head">
              <mat-slide-toggle formControlName="selectedMethod" [labelPosition]="'before'">
                <i>
                  {{
                  communicationMethods.controls[i]['controls'].CommunicationMethodType.value == 'NETWORK'? 'Network':
                  communicationMethods.controls[i]['controls'].CommunicationMethodType.value == 'CEPH'? 'CEPH':
                  communicationMethods.controls[i]['controls'].CommunicationMethodType.value == 'FTP'? 'FTP':'Local'
                  }}
                </i>
              </mat-slide-toggle>
            </div>

            <div class="set-content"
              [hidden]='communicationMethods.controls[i]["controls"].CommunicationMethodType.value === "LOCAL"'>
              <div [hidden]='communicationMethods.controls[i]["controls"].CommunicationMethodType.value === "CEPH"'>
                <mat-form-field>
                  <mat-label
                    *ngIf="communicationMethods.controls[i]['controls'].CommunicationMethodType.value == 'NETWORK'">
                    {{"labProfile.networkFolderPath"|translate}}
                  </mat-label>
                  <mat-label
                    *ngIf="communicationMethods.controls[i]['controls'].CommunicationMethodType.value == 'FTP'">
                    {{"labProfile.ftpServerPath"|translate}}
                  </mat-label>
                  <input matInput formControlName="NetworkPath"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && (communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'LOCAL' && communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'CEPH')"
                    placeholder="\\10.0.0.14\wsifiles" />
                  <mat-error *ngIf="hasError('NetworkPath', 'required', i)">
                    {{'forms.Required' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label> {{"labProfile.username"|translate}}</mat-label>
                  <input matInput formControlName="UserName" maxlength="50"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && (communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'LOCAL' && communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'CEPH')">
                  <mat-error *ngIf="hasError('UserName', 'required', i)">{{ 'forms.Required' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label> {{"labProfile.password"|translate}}</mat-label>
                  <input matInput formControlName="Password" maxlength="50"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && (communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'LOCAL' && communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'CEPH')"
                    [type]="hidePassword ? 'password' : 'text'">
                  <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                    <mat-icon>{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="hasError('Password', 'required', i)">{{ 'forms.Required' | translate }}</mat-error>
                </mat-form-field>
                <div class="fields-group"
                  [hidden]="communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'FTP'">
                  <mat-slide-toggle formControlName="IsSFtp" [labelPosition]="'before'">
                    <i> {{"labProfile.isSecureFileTransferProtocol"|translate}}</i>
                  </mat-slide-toggle>
                </div>
              </div>
              <div [hidden]='communicationMethods.controls[i]["controls"].CommunicationMethodType.value !== "CEPH"'>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label>{{"labProfile.apiUrl"|translate}}</mat-label>
                  <input matInput formControlName="CephApiURL"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && communicationMethods.controls[i]['controls'].CommunicationMethodType.value === 'CEPH'">
                  <mat-error *ngIf="hasError('CephApiURL', 'required', i)">{{ 'forms.Required' | translate
                    }}</mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label> {{"labProfile.accessKeyId"|translate}}</mat-label>
                  <input matInput formControlName="CephAccessKeyId"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && communicationMethods.controls[i]['controls'].CommunicationMethodType.value === 'CEPH'">
                  <mat-error *ngIf="hasError('CephAccessKeyId', 'required', i)">{{ 'forms.Required' | translate
                    }}</mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label>{{"labProfile.secretKey"|translate}}</mat-label>
                  <input matInput formControlName="CephSecretKey"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && communicationMethods.controls[i]['controls'].CommunicationMethodType.value === 'CEPH'">
                  <mat-error *ngIf="hasError('CephSecretKey', 'required', i)">{{ 'forms.Required' | translate
                    }}</mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label>{{"labProfile.defaultBucket"|translate}}</mat-label>
                  <input matInput formControlName="CephDefaultBucket"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && communicationMethods.controls[i]['controls'].CommunicationMethodType.value === 'CEPH'">
                  <mat-error *ngIf="hasError('CephDefaultBucket', 'required', i)">{{ 'forms.Required' | translate
                    }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <p class="has-error mt-2" *ngIf="isSubmitted && !checkCommunicationMethod()">
          {{ 'forms.Required' | translate }}</p>
      </div>

      <!-- <div [hidden]="isOurProfileVl">
        <div class="fields-group">
          <mat-slide-toggle formControlName="canSendToVL" [labelPosition]="'before'">
            <i>Can send requests to the Virtual Lab?</i>
          </mat-slide-toggle>
        </div>
        <div class="fields-group">
          <mat-slide-toggle formControlName="canSendToSC" [labelPosition]="'before'">
            <i>Can send requests to the Scanning Centers?</i>
          </mat-slide-toggle>
        </div>

        <div class="fields-group">
          <mat-form-field [hideRequiredMarker]='true' [hidden]='!formGroup.controls.canSendToSC.value'>
            <mat-label>Select Scanning Centers</mat-label>
            <mat-select disableOptionCentering multiple formControlName="scanningCenterIds"
              [required]='formGroup.controls.canSendToSC.value'>
              <mat-select-trigger>
                <span *ngFor="let item of formGroup.controls.scanningCenterIds.value">{{item.name}}</span>
              </mat-select-trigger>
              <mat-option [value]="item" *ngFor="let item of scanningCentersList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('scanningCenterIds', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->

      <button class="w-100" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
        (click)="saveRecord()">
        {{( recordId || isOurProfile || isOurProfileVl ? 'labProfile.update' : 'labProfile.addNew' )|translate}}
        {{"labProfile.educationalCenter"|translate}}
      </button>
    </form>
  </div>
</mat-dialog-content>
