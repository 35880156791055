import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-theme-header',
  templateUrl: './theme-header.component.html',
})
export class ThemeHeaderComponent implements OnInit {
  @Input() isBottom!: boolean;
  showNavbar!: boolean;
  activeDropdown: string | null = null;
  constructor(private translationService: TranslationService) {}

  ngOnInit() {}

  toggleDropdown(dropdownId: string) {
    if (this.activeDropdown === dropdownId) {
      this.activeDropdown = null;
    } else {
      this.activeDropdown = dropdownId;
    }
  }
  changeLanguage() {
    if (localStorage.getItem('language') === 'ar') {
      this.translationService.setLanguage('en');
    } else {
      this.translationService.setLanguage('ar');
    }
    window.location.reload();
  }
}
