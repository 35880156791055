import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-theme-layout',
  templateUrl: './theme-layout.component.html'
})
export class ThemeLayoutComponent implements OnInit {
  isBottom!: boolean;
  moduleLoading!: boolean;

  constructor(public router: Router, private scroll: ViewportScroller) {
    router.events?.subscribe((event: RouterEvent | any) => {
      this.navigationInterceptor(event);
    });
  }

  ngOnInit() {
  }

  // Shows and hides the moduleLoading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.moduleLoading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }

    // Set moduleLoading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      this.isBottom = true;
    } else {
      this.isBottom = false;
    }
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

}
