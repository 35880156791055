<div class="pagination_custom_bootstrap" *ngIf="pageCount > 1">
  <nav>
    <ul class="pagination">
      <li class="page-item" [class.disabled]='pageIndex == 0'>
        <a class="page-link" (click)="paginate(0)">
          <span class="material-icons">
            navigate_before
          </span>
        </a>
      </li>
      <li class="page-item other_item" *ngIf="(pageIndex + 4) > visibleNUmberPages">
        <a class="page-link bg-transparent">...</a>
      </li>
      <ng-container *ngFor="let in of counter(pageCount); let i = index">
        <li class="page-item" *ngIf="i <= maxNUmberPages && minNUmberPages <= i">
          <a class="page-link" (click)="paginate(i)" [class.active]='pageIndex == i'>{{i + 1}}</a>
        </li>
      </ng-container>
      <li class="page-item other_item" *ngIf="(pageCount -1) > maxNUmberPages">
        <a class="page-link bg-transparent">...</a>
      </li>

      <li class="page-item" [class.disabled]='pageIndex == (pageCount - 1)'>
        <a class="page-link" (click)="paginate(pageCount - 1)">
          <span class="material-icons">
            navigate_next
          </span>
        </a>
      </li>
    </ul>
  </nav>
</div>