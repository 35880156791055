<div class="app-chat">

  <!-- (click)="chatSeen(chat.user.id)" -->
  <div class="chat-rooms">

    <div class="chat-container chat-bot room active" [class.active]='openChat'>
      <div class="chat-head" cdkDrag (cdkDragStarted)="onDragStarted()" *ngIf="!openChat" (click)='openChatFun()'>
        <div class="name-with-image mb-3 mb-sm-0">
          <svg *ngIf="!openChat" xmlns="http://www.w3.org/2000/svg" version="1.1"
            xmlns:xlink="http://www.w3.org/1999/xlink" width="35" height="35" x="0" y="0" viewBox="0 0 48 48"
            style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
            <g>
              <path
                d="M41.95 35.93C41.49 40.45 37.65 44 33 44h-1.1c.06-.32.1-.66.1-1s-.04-.68-.1-1H33c3.52 0 6.44-2.61 6.92-6H41c.32 0 .64-.02.95-.07z"
                fill="#fffefe" opacity="1" data-original="#000000" class=""></path>
              <rect width="10" height="6" x="20" y="40" rx="3" fill="#fffefe" opacity="1" data-original="#000000"
                class=""></rect>
              <path
                d="M42.34 18.13A6.55 6.55 0 0 0 41 18h-1.71C38.1 10.64 31.7 5 24 5S9.9 10.64 8.71 18H7c-.46 0-.91.04-1.34.13C6.82 9.04 14.6 2 24 2s17.18 7.04 18.34 16.13zM41 34h-2c-1.103 0-2-.897-2-2V22c0-1.103.897-2 2-2h2c2.757 0 5 2.243 5 5v4c0 2.757-2.243 5-5 5zM2 29v-4c0-2.757 2.243-5 5-5h2c1.103 0 2 .897 2 2v10c0 1.103-.897 2-2 2H7c-2.757 0-5-2.243-5-5zM29 19H19a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h1v2.793a.5.5 0 0 0 .854.354L24 33.001h5a4 4 0 0 0 4-4v-6a4 4 0 0 0-4-4zm-3 10h-6a1 1 0 1 1 0-2h6a1 1 0 1 1 0 2zm2-4h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"
                fill="#fffefe" opacity="1" data-original="#000000" class=""></path>
            </g>
          </svg>
        </div>
      </div>
      <div class="chat-head" *ngIf="openChat" (click)='openChat = !openChat'>
        <div class="name-with-image mb-3 mb-sm-0" >
          <div *ngIf="openChat" class="name">
            <h5>Chat with us</h5>
          </div>
        </div>
        <div class="actions d-flex">
          <button mat-icon-button class="btn-close" *ngIf="messages.length > 1" [matTooltip]="'Clear chat'" (click)="clearChat($event)">
            <mat-icon>delete</mat-icon>
          </button>

          <button mat-icon-button class="btn-close">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </div>
      <div class="chat-area-container">
        <div class="chat-area scroll-area" [id]="'chat'"> <!-- [id]="'chat-' + i" -->
          <!-- <div class="dataLoading" *ngIf='loading'>
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
          </div> -->
          <!-- *ngIf="!chat.loading" -->
          <div class="chatbot">
            <ul>
              <li *ngFor="let message of messages;let i = index" [class.mine]='message.mine'>
                <div>
                  <p>{{ message.name }}</p>
                  <ng-container *ngIf="message.childs && message.childs.length && !message.mine">
                    <ul class="mt-2">
                      <ng-container *ngFor="let item of message.childs">
                        <li class="child_mesg" (click)="!message.mine ? selectNode(item.name) : null">
                          {{item.name}}
                        </li>
                      </ng-container>

                    </ul>
                  </ng-container>

                </div>
              </li>
              <li *ngIf="loading" class="loadingMsg">
                <div class="typing">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </li>
            </ul>
            <!-- <div *ngIf="!selectedNode">
              <h4>Select a Category</h4>
              <ul>
                <li *ngFor="let node of data" (click)="selectNode(node)">
                  {{ node.name }}
                </li>
              </ul>
            </div>

            <div *ngIf="selectedNode && !selectedChild">
              <button (click)="backToNodes()">Back to Categories</button>
              <h4>{{ selectedNode.name }}</h4>
              <ul>
                <li *ngFor="let child of selectedNode.childs" (click)="selectChild(child)">
                  {{ child.name }}
                </li>
              </ul>
            </div>

            <div *ngIf="selectedChild">
              <button (click)="backToChildren()">Back to {{ selectedNode.name }}</button>
              <h4>{{ selectedChild.name }}</h4>
              <p><strong>ISIC Code:</strong> {{ selectedChild.isicCode }}</p>
            </div> -->
          </div>
        </div>
        <!-- <div class="send-form">
          <div>
             <input type="text" placeholder="Write your message"
              [(ngModel)]='chat.message'
              (keydown.enter)='send(chat)'>
             <button mat-icon-button class="btn">
              <img src="./assets/images/icons/ic_send.svg" alt="">
            </button>
          </div>
        </div> -->
      </div>
    </div>
  </div>

</div>
