<mat-dialog-content class="confirmation">
  <div class="mat-dialog-body">
    <figure class="mb-5 text-center">
      <img class="img-fluid" [src]="data.img" alt="">
    </figure>
    <h4 class="label-form text-center bold" *ngIf="data.title" [innerHTML]="data.title">
    </h4>
    <p class="label-helper text-center mb-3" *ngIf="data.message" [innerHTML]="data.message"> </p>
    <div class="d-flex align-items-center justify-content-center mt-2">
      <button class="mr-2 {{data.btnCancelClass}}" mat-raised-button color="grey" mat-dialog-close
        *ngIf="data.btnCancel">
        {{data.btnCancelTitle?data.btnCancelTitle:('swalMsg.cancel'|translate)}}
      </button>
      <button class="{{data.btnClass}}" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
        (click)="confirm()">
        {{data.btnTitle?data.btnTitle:('swalMsg.ok'|translate)}}
      </button>
    </div>
  </div>
</mat-dialog-content>