
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ModuleDetail,
  ModuleLessonDetail
} from 'src/app/models/trainer/training-course-details/CourseDetails';
import { Router } from '@angular/router';
import { TrainerService } from 'src/app/services/trainer.service';
import { HttpResponse } from '@angular/common/http';
import { SwalService } from 'src/app/services/swal.service';
import { TranslateService } from '@ngx-translate/core';
import { QuizQuestionType } from 'src/app/models/trainer/training-courses-list/enums/QuizQuestionType';

@Component({
  selector: 'app-quiz-type',
  templateUrl: './quiz-type.component.html',
})
export class QuizTypeComponent implements OnInit {
  @Input() courseId: any;
  @Input() lessonId: any;
  @Input() moduleId: any;
  @Input() order;
  @Input() selectedType;
  @Input() isSlideQuiz = false;
  @Input() slideQuizData: any;
  @Input() assessmentType: any;
  @Input() moduleDetails: ModuleDetail;
  @Input() _formGroup: FormGroup;
  isSubmitted = false;
  loading = false;
  dataLoading = true;
  moduleLessonDetail: ModuleLessonDetail;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private service: TrainerService,
    private swalService: SwalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    console.log(this._formGroup);
    if (this.lessonId) {
      this.getModuleLessonById();
    } else {
      this.dataLoading = false;
    }
  }

  getModuleLessonById() {
    this.service.getModuleLessonById(this.lessonId, false).subscribe(
      (data) => {
        this.moduleLessonDetail = data as ModuleLessonDetail;
        this.dataLoading = false;
      },
      (error) => {
        this.dataLoading = false;
      }
    );
  }

  saveRecord() {
    console.log(this.questionsArray);
    let data: any[] = [];
    const qustionModel = {} as any;
    let attachments = {};
    const Model: any = {
      courseModuleId: this.moduleId,
      contentTypeId: this.selectedType,
      title: this._formGroup.controls.title.value,
      passPercentage: this._formGroup.controls.percent.value,
      isLimitNumberOfRetake: this._formGroup.controls.isLimitnumberOfQuiz.value,
      maxNumberOfRetake: this._formGroup.controls.numberOfQuiz.value,
      Description: '',
      Order: this.lessonId  ? this.moduleLessonDetail.order : this.order,
      assessmentType: null,
      ExamVersionNumber: 1,
      ExamQuestionsType: null,
      AssessmentDuration: 0,
      IsRandomized: false,
      ExamQuestions:this.questionsArray.value.map((ele1: any , optionIndex) => {
        return {
          id: this.lessonId ? (ele1.id ? ele1.id : 0) : 0,
          QuestionBankQuestion: null,
          ExamVersionNumber: 1,
          question: ele1?.questionText,
          order: optionIndex,
          isPenalty: ele1?.penalty ? true : false,
          moduleLessonId: this.lessonId,
          questionTypeId: ele1?.questionType?.id,
          DeletedAttachmentsId: ele1?.deteledFiles,
          RandomizingLinkedOrder: null,
          scoringPoint: ele1?.totalPoints ? parseInt(ele1?.totalPoints) : 0,
          quizQuestionsAnswers: ele1.options.map(
            (ele2: any, optionIndex: number) => {
              return {
                answer: ele2.text || '',
                isCorrect: ele2.isCorrectAnswer,
                scoringPoint: ele2.scoringPoints
                  ? parseInt(ele2.scoringPoints)
                  : 0,
                respondentMessage: ele2.note || '',
                order: optionIndex + 1,
                quizQuestionId: this.lessonId ? ele2.quizQuestionId ? ele2.quizQuestionId : 0 : 0,
                id: this.lessonId ? (ele2.id ? ele2.id : 0) : 0,
              };
            }
          ),
        };
      }),
    };
    this.questionsArray.value.forEach((question, questionIndex) => {
      if (question?.files?.length) {
        attachments[`attachment_1_${questionIndex}`] = question.files.filter(item => item instanceof File);
      }
    });
    if (this.lessonId) {
      Model.id = this.lessonId;
    }

    data.push(Model);

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    for (const key in attachments) {
      const element = attachments[key];
      if (element.length) {
        element.forEach(file => {
          formData.append(key, file)
        });
      }
    }


    this.isSubmitted = true;
    if (this._formGroup.valid) {
      this.loading = true;
      this.service
        .createModuleLessons(formData, this.lessonId)
        .subscribe(
          (res) => {
            if (res instanceof HttpResponse) {
              this.swalService
                .swalSuccess(
                  this.lessonId
                    ? this.translate.instant('swalMsg.updatedsuccessfully')
                    : this.translate.instant('swalMsg.savedsuccessfully')
                )
                .then(() => {
                  this.service.activeTab.next(this.moduleDetails.isFinalAssessment ? 'finalAssessment' : this.moduleDetails.isPreDiagnose ? 'preDiagnose' : 'Modules');
                  this.router.navigate([
                    '/admin/trainingCourses/details',
                    this.courseId,
                  ]);
                });
            }
            //this.router.navigate(['/admin/trainingCourses/details', this.courseId]);
          },
          (error) => {
            this.loading = false;
            //console.log('Error', error);
          }
        );
    }
  }

  cancel() {
    this.router.navigate(['/admin/trainingCourses/details', this.courseId]);
  }

  get f() {
    return this._formGroup.controls;
  }

  // questionsArray
  get questionsArray(): FormArray {
    return this._formGroup.controls.questionsArray as FormArray;
  }

  hasError(controlName: string, errorName: string) {
    return this._formGroup.controls[controlName].hasError(errorName);
  }

}
