import { Component, HostListener, NgZone, OnInit, OnDestroy } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthUser } from 'src/app/models/auth/AuthUser';
import { DefaultCurrency } from 'src/app/models/currency/DefaultCurrency';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SignalRService } from 'src/app/services/signal-rservice.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  moduleLoading: boolean;
  isPreview: boolean = false;
  currencyLoading: boolean;
  currentUser = new AuthUser();
  defaultCurrency: DefaultCurrency;

  constructor(
    public navService: NavigationService,
    public router: Router,
    public zone: NgZone,
    private signalRService: SignalRService,
    private currencyService: CurrencyService,
    private auth: AuthenticationService
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    this.currentUser = this.auth.currentUserValue;
    this.defaultCurrency = this.currencyService.defaultCurrencyValue;
  }

  ngOnInit(): void {
    if(this.router.url.includes('/admin/courses')) {
      this.isPreview = true
    }
    this.signalRService.handleNewLabUpdateEvent(this, this.labUpdated);
    this.updateSidebar();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(routeChange => {
        if (this.isMobile()) {
          this.navService.sidebarState.sidenavOpen = false;
        }
      });
    this.getDefaultCurrency();
  }

  getDefaultCurrency() {
    this.currencyLoading = true;
    this.currencyService.getDefaultCurrency().subscribe(
      (data: DefaultCurrency) => {
        this.currencyLoading = false;
        this.currencyService.defaultCurrencySubject.next(data);
        localStorage.setItem('defaultCurrency', JSON.stringify(data));
      }, err => {
        console.error(err);
      }
    );
  }

  labUpdated(context, labId) {
    context.zone.run(() => {
      if (context.currentUser && context.currentUser.ObjectValue === labId) {
        context.auth.RefeshUserToken(true).subscribe(data => { });
      }
    });
  }

  isMobile() {
    return window && window.matchMedia('(max-width: 500px)').matches;
  }

  // Shows and hides the moduleLoading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.moduleLoading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }

    // Set moduleLoading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
  }

  updateSidebar() {
    if (this.isMobile()) {
      this.navService.sidebarState.sidenavOpen = false;
    } else {
      this.navService.sidebarState.sidenavOpen = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateSidebar();
  }

  ngOnDestroy() {
    /* if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    } */
  }

}
