<div class="sidebar-inner" [ngClass]="{open: navService.sidebarState.sidenavOpen}">
  <div class="sidebar-header">
    <img src="assets/images/logo/logo.png" alt="logo" class="logo-lg img-fluid">
    <img src="assets/images/logo/smallLogo.svg" alt="logo" class="logo-sm">
  </div>
  <div class="sidebar-list-menu">
    <ul>
      <ng-container *ngFor="let item of nav">
        <li *ngIf='item.canAccess' [class.active]="item.active || isActiveMenu(item.state)">
          <a [routerLink]="item.state" *ngIf="item.type === 'link'" routerLinkActive="active"
            (click)="selectItem(item)">
            <div class="item-inner">
              <svg width="25" height="25" viewBox="0 0 25 25" [innerHTML]="item.icon | sanitizeHtml"></svg>
              <div class="item-content">
                <span>{{item.title |translate}}</span>
                <span class="desc">{{item.description |translate}}</span>
              </div>
            </div>
          </a>
          <ng-container *ngIf="item.type === 'dropDown'">
            <a (click)="selectItem(item)" routerLinkActive="active">
              <div class="item-inner">
                <svg width="25" height="25" viewBox="0 0 25 25" [innerHTML]="item.icon | sanitizeHtml"></svg>
                <div class="item-content">
                  <span>{{item.title |translate}}</span>
                  <span class="desc">{{item.description |translate}}</span>
                </div>
              </div>
              <i class="material-icons">
                {{item.active || isActiveMenu(item.state)?'expand_less':'expand_more'}}
              </i>
            </a>
            <ul class="childNav">

              <ng-container *ngFor="let sub of item?.sub">

                <li *ngIf='sub.canAccess' class="nav-item">
                  <a routerLink="{{sub.state}}" routerLinkActive="active">
                    <span class="item-name">{{sub.title  |translate}}</span>
                  </a>
                </li>

              </ng-container>

            </ul>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="sidebar-footer">
    <p>
      {{ 'footer.copy-right' | translate: {date: today | date:'y'} }}
    </p>
  </div>
</div>
<div [ngClass]="{open: navService.sidebarState.sidenavOpen}" class="sidebar-overlay" (click)="toggelSidebar()"></div>