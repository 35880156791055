export enum ContentType {
  READING = 1,
  VIDEO = 2,
  QUIZ = 3,
  ASSIGNMENT = 4,
}
export enum FinalAssessmentTypeEnum {
  PathologyExam = 1,
  TraditionallyExam = 3,
}
export enum FinalAssessmentType {
  TraditionallyExam = 'TraditionallyExam',
  PathologyExam = 'PathologyExam',
}
