<div mat-dialog-title>
  <h2 [hidden]="isPathologist">
    {{ (userId ? 'userForm.updateUser' : 'userForm.addNewUser' )|translate}}
  </h2>
  <h2 *ngIf="isPathologist">
    <ng-container *ngIf="userId; else elseTemplateAddNew">
      {{(data.isTrainer?'userForm.updateTrainer':'userForm.updateTrainee')|translate}}
    </ng-container>
    <ng-template #elseTemplateAddNew>
      {{(data.isTrainer?'userForm.addNewTrainer':'userForm.addNewTrainee')|translate}}
    </ng-template>

    <!-- {{ userId ? 'Update' : 'Add New' }} {{data.isTrainer?'Trainer':'Trainee'}} -->
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <form [formGroup]="formGroup">
      <div *ngIf="this.data?.record && (loadingCA || citiesLoading || regionLoading)" class="dataLoading">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
      </div>

      <div class="alert alert-info alert-dismissible fade show" role="alert"
        *ngIf="disableAll && formGroup.controls.firstName.value">
        {{formGroup.controls.firstName.value}}
        <button type="button" class="close" (click)="clearAllFields();enableAllFields();disableAll = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div>
        <div class="mat-dialog-inner" [hidden]="(model != 'LAB_USER' && model != 'Lab') || data.isModuleAdmin">
          <mat-form-field>
            <mat-label>{{"userForm.trainingCenter"|translate}}</mat-label>
            <mat-select disableOptionCentering formControlName="lab" [required]="model === 'LAB_USER'"
              [disabled]="model === 'Lab'" (selectionChange)='checkChaneLabReviwer()'>
              <mat-option [value]="item.id" *ngFor="let item of mainLabsList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('lab', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="mat-dialog-inner"
          [hidden]="(model != 'SCANNING_CENTER_USER' && model != 'ScanningCenter') || data.isModuleAdmin">
          <mat-form-field>
            <mat-label>{{"userForm.scanningCenter"|translate}}</mat-label>
            <mat-select disableOptionCentering formControlName="scanningCenterId"
              (selectionChange)='getSCCoverageAreas()' [required]="model === 'SCANNING_CENTER_USER'"
              [disabled]="model === 'ScanningCenter'">
              <mat-option [value]="item.id" *ngFor="let item of scList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('scanningCenterId', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="mat-dialog-inner"
          [hidden]="(model != 'HOSPITAL_USER' && model != 'Hospital') || data.isModuleAdmin">
          <mat-form-field>
            <mat-label>{{"userForm.hospital"|translate}}</mat-label>
            <mat-select disableOptionCentering formControlName="hospitalId" [required]="model === 'HOSPITAL_USER'"
              [disabled]="model === 'Hospital'">
              <mat-option [value]="item.id" *ngFor="let item of entityList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('hospitalId', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="mat-dialog-inner" [hidden]="(model != 'CLINIC_USER' && model != 'Clinic') || data.isModuleAdmin">
          <mat-form-field>
            <mat-label>{{"userForm.clinic"|translate}}</mat-label>
            <mat-select disableOptionCentering formControlName="clinicId" [required]="model === 'CLINIC_USER'"
              [disabled]="model === 'Clinic'">
              <mat-option [value]="item.id" *ngFor="let item of entityList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('clinicId', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="mat-dialog-inner" [hidden]="model === 'profile' || isPathologist">
          <h3 class="subtitle-form">
            {{"userForm.userRole"|translate}}<span> {{"userForm.multipleSelect"|translate}} </span>
          </h3>
          <div class="checkbox-custom-list">
            <mat-selection-list class="selection-btns" formControlName="role"
              [required]="model != 'profile' && !isPathologist">
              <div class="col-6 mb-3" *ngFor="let role of roles;let i = index">
                <mat-list-option [value]="role.value" [disabled]="role.disabled" (click)="checkRoles($event)">
                  {{role.key}}</mat-list-option>
              </div>
            </mat-selection-list>
            <mat-error class="has-error" *ngIf="hasError('role', 'required') && isSubmitted">
              {{ 'forms.Required' | translate }}
            </mat-error>
          </div>
        </div>
        <div class="mat-dialog-inner">
          <h3 class="subtitle-form">
            <ng-container *ngIf="!isPathologist">{{"userForm.userDetails"|translate}}</ng-container>
            <ng-container
              *ngIf="isPathologist">{{(data.isTrainer?'userForm.trainerDetails':'userForm.traineeDetails')|translate}}
            </ng-container>
          </h3>
          <div class="row">
            <div class="col-12" [hidden]="!isPathologist">
              <mat-form-field>
                <mat-label>{{"userForm.idType"|translate}}</mat-label>
                <mat-select disableOptionCentering formControlName="identityType" (selectionChange)='checkUserFound()'
                  [required]="isPathologist">
                  <mat-option [value]="item" *ngFor="let item of idTypesList">
                    <span>{{item.name}}</span>
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('identityType', 'required')">{{ 'forms.Required' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12"
              [hidden]="!isPathologist || !formGroup.controls.identityType.value || formGroup.controls.identityType.value?.code !== 'OT'">
              <mat-form-field>
                <mat-label>{{"userForm.otherIDType"|translate}}</mat-label>
                <input matInput formControlName="OtherIdentityType" maxlength="50" (keyup)="checkUserFound()"
                  [required]="isPathologist && formGroup.controls.identityType.value?.code == 'OT'">
                <mat-error *ngIf="hasError('OtherIdentityType', 'required')">
                  {{ 'forms.Required' | translate }}
                </mat-error>
                <mat-error *ngIf="hasError('OtherIdentityType', 'maxLength')">
                  {{"forms.maxLengthIsCharacters"|translate:{num:50} }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12" [hidden]="!isPathologist">
              <mat-form-field>
                <mat-label>
                  {{
                  formGroup.controls.identityType.value && formGroup.controls.identityType.value?.code !== 'OT'?
                  formGroup.controls.identityType.value.name:
                  formGroup.controls.identityType.value && formGroup.controls.identityType.value?.code === 'OT'?
                  formGroup.controls.OtherIdentityType.value:('userForm.nationalID'|translate)
                  }}
                </mat-label>
                <input matInput formControlName="identityId" maxlength="50" (keyup)="checkUserFound()"
                  [required]="isPathologist">
                <mat-error *ngIf="hasError('identityId', 'required')">{{ 'forms.Required' | translate }}</mat-error>
                <mat-error *ngIf="hasError('identityId', 'maxLength')">
                  {{"forms.maxLengthIsNumber"|translate:{num:50} }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12"
              [hidden]="!formGroup.controls.role.value && !isPathologist || data.isTrainer || model != 'profile'">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>{{"userForm.scfhsNo"|translate}}</mat-label>
                  <input matInput formControlName="scfhs" maxlength="50" [required]="hasPermission('Trainee')">
                  <mat-error *ngIf="hasError('scfhs', 'required')">{{ 'forms.Required' | translate }}</mat-error>
                  <mat-error *ngIf="hasError('scfhs', 'maxLength')">
                    {{"forms.maxLengthIsCharacters"|translate:{num:50} }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <ngx-intl-tel-input [customPlaceholder]="" [enableAutoCountrySelect]="true"
                  [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [maxLength]="50"
                  [numberFormat]="PhoneNumberFormat.International" [phoneValidation]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [searchCountryFlag]="true"
                  [searchCountryFlag]="true" [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryISO"
                  [separateDialCode]="true" formControlName="contactNumber" name="contactNumber"
                  (keyup)="checkUserFound()">
                </ngx-intl-tel-input>
                <p class="has-error" *ngIf="
                    formGroup.controls.contactNumber.errors &&
                    formGroup.controls.contactNumber.errors.required && isSubmitted">
                  {{ 'forms.Required' | translate }}
                </p>

                <p class="has-error" *ngIf="
                    formGroup.controls.contactNumber.errors &&
                    formGroup.controls.contactNumber.errors.validatePhoneNumber">
                  {{"labProfile.enterValidNumberAccordingSelectedCountry"|translate}}
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>{{"general.firstName"|translate}}</mat-label>
                <input matInput formControlName="firstName" maxlength="50" [readonly]="hasPermission('Trainee')">
                <mat-error *ngIf="hasError('firstName', 'required')">{{ 'forms.Required' | translate }}</mat-error>
                <mat-error *ngIf="hasError('firstName', 'maxLength')">
                  {{"forms.maxLengthIsCharacters"|translate:{num:50} }}

                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>{{"general.lastName"|translate}} </mat-label>
                <input matInput formControlName="lastName" maxlength="50" [readonly]="hasPermission('Trainee')">
                <mat-error *ngIf="hasError('lastName', 'required')">{{ 'forms.Required' | translate }}</mat-error>
                <mat-error *ngIf="hasError('lastName', 'maxLength')">
                  {{"forms.maxLengthIsCharacters"|translate:{num:50} }}

                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12" [hidden]="hasPermission('Trainee')">
              <mat-form-field>
                <mat-label>{{"general.email"|translate}}</mat-label>
                <input matInput formControlName="email" maxlength="50">
                <mat-error *ngIf="hasError('email', 'required')">{{ 'forms.Required' | translate }}</mat-error>
                <mat-error *ngIf="hasError('email', 'invalid')">{{ 'forms.Invalid-Email' | translate }}</mat-error>
                <mat-error *ngIf="hasError('email', 'pattern')">{{ 'forms.Invalid-Email' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="col-12" *ngIf="checkCanShareToConCural()">
              <div class="fields-group">
                <mat-slide-toggle formControlName="canShareToConCural" [labelPosition]="'before'">
                  <i>Have Account In Concural?</i>
                </mat-slide-toggle>
              </div>
            </div> -->
          </div>
        </div>
        <div class="mat-dialog-inner"
          *ngIf="data.model == 'profile' && isPathologist && data.record && data.record.canShareToConCural">
          <h3 class="subtitle-form">{{"userForm.concuralAccount"|translate}}</h3>
          <mat-form-field>
            <mat-label>{{"userForm.username"|translate}}</mat-label>
            <input matInput autocomplete="off" [required]="formGroup.controls.conCuralPassword.value ? true : false"
              formControlName="conCuralUserName" minlength="6" maxlength="20">
            <mat-error *ngIf="hasError('conCuralUserName', 'minlength') || hasError('conCuralUserName', 'pattern')">
              {{"userForm.userDetails"|translate}}
            </mat-error>
            <mat-error *ngIf="hasError('conCuralUserName', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'forms.Password' | translate }}</mat-label>
            <input matInput autocomplete="new-password"
              [required]="formGroup.controls.conCuralUserName.value ? true : false" formControlName="conCuralPassword"
              [type]="hidePassword ? 'password' : 'text'" maxlength="50" minlength="8">
            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
              <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="hasError('conCuralPassword', 'minlength')">
              {{"userForm.invalidPassword"|translate}}
            </mat-error>
            <mat-error *ngIf="hasError('conCuralPassword', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="mat-dialog-inner">
          <h3 class="subtitle-form">{{"general.address"|translate}}</h3>
          <div class="row">
            <div class="col-12">
              <mat-form-field>
                <mat-label>{{"general.country"|translate}}</mat-label>
                <mat-select disableOptionCentering formControlName="country" (selectionChange)='getRegions(true)'>
                  <mat-option [value]="item.id" *ngFor="let item of countryList">
                    <span>{{item.name}}</span>
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix *ngIf="formGroup.controls.country.value" (click)="clearCountry()">clear</mat-icon>
                <mat-error *ngIf="hasError('country', 'required')">{{ 'forms.Required' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field [class.loading-field]='regionInputLoading'>
                <mat-label>{{"general.regionGovernorateEmirate"|translate}}</mat-label>
                <mat-select disableOptionCentering formControlName="region" (selectionChange)='getCities(true)'
                  [disabled]="!regionList.length || regionInputLoading || disableAll"
                  [required]="formGroup.controls.country.value">
                  <mat-option [value]="item.id" *ngFor="let item of regionList">
                    <span>{{item.name}}</span>
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('region', 'required')">{{ 'forms.Required' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field [class.loading-field]='citiesInputLoading'>
                <mat-label>{{"general.city"|translate}}</mat-label>
                <mat-select disableOptionCentering formControlName="cityId"
                  [disabled]="!cityList.length || citiesInputLoading || disableAll"
                  [required]="formGroup.controls.country.value">
                  <mat-option [value]="item.id" *ngFor="let item of cityList">
                    <span>{{item.name}}</span>
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('cityId', 'required')">{{ 'forms.Required' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field>
                <mat-label>{{"general.streetAddress"|translate}}</mat-label>
                <textarea matInput formControlName="address" maxlength="500"></textarea>
                <mat-error *ngIf="hasError('address', 'required')">{{ 'forms.Required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div [hidden]="!isPathologist">
          <div>
            <div class="mat-dialog-inner">
              <h3 class="subtitle-form">
                {{"general.subspecialty"|translate}} <span> {{"userForm.multipleSelect"|translate}} </span>
              </h3>
              <div class="row">
                <div class="col-12">
                  <mat-form-field>
                    <mat-label> {{"general.subspecialty"|translate}}</mat-label>
                    <mat-select disableOptionCentering multiple formControlName="subspecialties"
                      [required]="isPathologist">
                      <mat-select-trigger>
                        <span *ngFor="let item of formGroup.controls.subspecialties.value">
                          {{item.subSpeciality}}
                        </span>
                      </mat-select-trigger>
                      <mat-option [value]="item" *ngFor="let item of subspecialtieList">
                        <span>{{item.subSpeciality}}</span>
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasError('subspecialties', 'required')">
                      {{ 'forms.Required' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- *ngIf="data.pathologistLabType == 'VIRTUAL_LAB' || data.pathologistLabType == 'LAB'" -->
                <!-- *ngIf="model == 'PATHOLOGIST_USER'" -->
                <!-- <div class="col-12" *ngIf="data.pathologistLabType == 'VIRTUAL_LAB' || data.pathologistLabType == 'LAB'"
                  [hidden]="model === 'profile'">
                  <div class="fields-group">
                    <mat-slide-toggle formControlName="canSendToSO" [labelPosition]="'before'">
                      <i>Can request a second opinion from another Pathologist?</i>
                    </mat-slide-toggle>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- *ngIf="data.pathologistLabType == 'VIRTUAL_LAB' || data.pathologistLabType == 'LAB'" -->
            <!-- *ngIf="model == 'PATHOLOGIST_USER'" -->
            <div class="mat-dialog-inner"
              *ngIf="data.pathologistLabType == 'VIRTUAL_LAB' || data.pathologistLabType == 'LAB'"
              [hidden]="model === 'profile' || data.isTrainer">
              <div class="fields-group" hidden>
                <mat-slide-toggle formControlName="HasReviewer" [labelPosition]="'before'">
                  <i>{{"userForm.hasTrainer"|translate}}</i>
                </mat-slide-toggle>
              </div>
              <h3 class="subtitle-form" [hidden]="!formGroup.controls.HasReviewer.value">
                {{"userForm.responsibleTrainer"|translate}}
              </h3>
              <div class="row" [hidden]="!formGroup.controls.HasReviewer.value">
                <div class="col-12" hidden>
                  <mat-form-field>
                    <mat-label>{{"userForm.position"|translate}} </mat-label>
                    <mat-select disableOptionCentering formControlName="position"
                      (selectionChange)='getUsersByPosition()' [required]="formGroup.controls.HasReviewer.value">
                      <mat-option [value]="item.value" *ngFor="let item of positionList">
                        <span>{{item.key}}</span>
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasError('position', 'required')">
                      {{ 'forms.Required' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-form-field>
                    <mat-label>{{"userForm.trainer"|translate}}</mat-label>
                    <mat-select disableOptionCentering formControlName="reviewer" [disabled]="!reviewerList.length"
                      [required]="formGroup.controls.HasReviewer.value">
                      <mat-option [value]="item.id" *ngFor="let item of reviewerList">
                        <span>{{item.name}}</span>
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasError('reviewer', 'required')">
                      {{ 'forms.Required' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="mat-dialog-inner" [hidden]="!formGroup.controls.role.value && !isPathologist || data.isTrainer">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table_availability">
                    <thead>
                      <tr>
                        <th width="40%">{{"userForm.availabilityForTraining"|translate}}</th>
                        <th width="30%">{{"general.from"|translate}}</th>
                        <th width="30%">{{"general.to"|translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let availability of availabilityList; let i = index">
                        <td width="40%">
                          <mat-checkbox [(ngModel)]="availability.isActive" (change)="changeEvent($event,i)"
                            [disabled]="disableAll" [ngModelOptions]="{standalone: true}">
                            {{availability.availableDay}}
                          </mat-checkbox>
                        </td>
                        <td width="30%">
                          <mat-form-field class="form-group matTimePicker" appearance="fill"
                            [hidden]="!availability.isActive">
                            <input matInput [ngxTimepicker]="timePickerFrom" [format]="24" [disabled]="disableAll"
                              [(ngModel)]="availability.availableTimeFrom" [ngModelOptions]="{standalone: true}"
                              readonly [required]="availability.isActive" [disableClick]="true"
                              (ngModelChange)="changeTimeEvent($event,i)">
                            <mat-icon *ngIf="availability.availableTimeFrom && !disableAll"
                              (click)="availability.availableTimeFrom = ''" class="clear-icon">clear
                            </mat-icon>
                            <ngx-material-timepicker-toggle [for]="timePickerFrom">
                            </ngx-material-timepicker-toggle>
                            <ngx-material-timepicker #timePickerFrom (timeSet)="setEndTimeMin(i)">
                            </ngx-material-timepicker>
                          </mat-form-field>
                        </td>
                        <td width="30%">
                          <mat-form-field class="form-group matTimePicker" appearance="fill"
                            [hidden]="!availability.isActive">
                            <input matInput [ngxTimepicker]="timePickerTo" [format]="24" [min]='availability.endTimeMin'
                              [disabled]="disableAll" [(ngModel)]="availability.availableTimeTo"
                              [ngModelOptions]="{standalone: true}" readonly [required]="availability.isActive"
                              [disableClick]="true" (ngModelChange)="changeTimeEvent($event,i)">
                            <mat-icon *ngIf="availability.availableTimeTo && !disableAll"
                              (click)="availability.availableTimeTo = ''" class="clear-icon">clear
                            </mat-icon>
                            <ngx-material-timepicker-toggle [for]="timePickerTo">
                            </ngx-material-timepicker-toggle>
                            <ngx-material-timepicker #timePickerTo>
                            </ngx-material-timepicker>
                          </mat-form-field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <mat-error class="has-error" *ngIf="!isValidTimeList && isSubmitted && isPathologist">
                  {{ 'forms.Required' | translate }}
                </mat-error>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="checkCoverageAreas()">
          <div class="mat-dialog-inner" [hidden]='!formGroup.controls.role.value'>
            <h3 class="subtitle-form">
              {{"userForm.coverageAreas"|translate}}
            </h3>
            <app-tree-field [required]='true' [isSubmitted]='isSubmitted' [recordsTree]='citiesTree'
              [recordsLoading]="loadingCAInput" [isDisabled]="!citiesTree.length || loadingCAInput"
              [detailsSelectedRecords]='detailsSelectedCities' (selected)="selectedCities = $event"></app-tree-field>
          </div>
        </div>

        <div class="sets-list fields-group" *ngIf="checkMultiCoveredArea() && countryList && countryList.length">
          <div class="head">
            <h3 class="subtitle-form">{{"userForm.coverageAreas"|translate}}</h3>

            <button type="button" class="btn-trans-round" (click)="openNewArea()">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                <path id="plus-icon"
                  d="M10.25,4.5H6.5V.75A.75.75,0,0,0,5.75,0h-.5A.75.75,0,0,0,4.5.75V4.5H.75A.75.75,0,0,0,0,5.25v.5a.75.75,0,0,0,.75.75H4.5v3.75a.75.75,0,0,0,.75.75h.5a.75.75,0,0,0,.75-.75V6.5h3.75A.75.75,0,0,0,11,5.75v-.5A.75.75,0,0,0,10.25,4.5Zm0,0" />
              </svg>
              <span> {{"userForm.addOtherCoverageAreas"|translate}}</span>
            </button>
          </div>

          <div class="sets-conatiner">
            <div class="set-item collapsed" *ngFor="let record of coveredAreaList; let i = index;"
              [class.collapsed]='activeAreaIndex !== i'>
              <div class="set-head" (click)="toggleAreaset(i)">
                <div>
                  <span>{{"userForm.areas"|translate}} {{i + 1}}: </span>
                  <ng-container *ngIf='record.country && record.country.name'>{{record.country.name}}</ng-container>
                </div>
                <mat-icon class="more">unfold_more</mat-icon>
                <mat-icon class="less">unfold_less</mat-icon>
              </div>

              <div class="set-content">
                <app-covered-area-form [record]="record" [currentIndex]="i" [countryList]="countryList"
                  [coveredAreaList]="coveredAreaList" (deleteItem)='deleteArea()' (newRecord)='pushNewArea($event)'>
                </app-covered-area-form>
              </div>
            </div>
          </div>
          <p class="has-error mt-2" *ngIf="isSubmitted && !coveredAreaList[0].country">
            {{ 'forms.Required' | translate }}</p>
        </div>
      </div>


      <button class="w-100" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
        (click)="saveRecord()">
        <ng-container *ngIf="!isPathologist">
          {{( userId ? 'general.update' : 'general.add')|translate }} {{"general.user"|translate}}
        </ng-container>

        <ng-container *ngIf="isPathologist">
          {{ ( userId ? 'general.update' : 'general.add')|translate }}
          {{(data.isTrainer?'general.trainer':'general.trainee')|translate}}
        </ng-container>
      </button>
    </form>
  </div>
</mat-dialog-content>
