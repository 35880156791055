<form class="form-module-content-type" [formGroup]="_formGroup">
  <div class="module-content-type" *ngIf="!isSlideQuiz">
    <div class="title">
      <h2>{{"courseDetails.contentDetails"|translate}}</h2>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="mat-dialog-inner mb-4">
          <h3 class="subtitle-form">{{"quiz.quizTitle"|translate}}</h3>
          <mat-form-field class="matSuffix-control">
            <mat-label>{{"quiz.quizTitle"|translate}}</mat-label>
            <input matInput formControlName="title" maxlength="100" [required]="!isSlideQuiz">
            <mat-error *ngIf="hasError('title', 'required')">
              {{'forms.Required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row general-details-title mb-4">
      <div class="col-12">
        <h2>{{"quiz.quizGeneralDetails"|translate}}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9 mb-4">
        <div class="mat-dialog-inner">
          <h3 class="subtitle-form">{{"quiz.quizPassPercent"|translate}}</h3>
          <mat-form-field class="matSuffix-control">
            <mat-label>{{"quiz.quizPassPercent"|translate}}</mat-label>
            <input matInput formControlName="percent" [required]="!isSlideQuiz" appOnlynumber>
            <mat-icon matSuffix> percent</mat-icon>
            <mat-error *ngIf="hasError('percent', 'required')">
              {{'forms.Required' | translate}}
            </mat-error>
            <mat-error *ngIf="hasError('percent', 'min')">
              {{"forms.minNumberIs"|translate:{num:1} }}
            </mat-error>
            <mat-error *ngIf="hasError('percent', 'max')">
              {{"forms.maxNumberIs"|translate:{num:100} }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-12 mb-4"
        *ngIf="!moduleDetails.isFinalAssessment && !moduleDetails.isPreDiagnose && !moduleDetails.isAssessment">
        <div class="mat-dialog-inner">
          <div class="fields-group toggle">
            <mat-slide-toggle formControlName="isLimitnumberOfQuiz" [labelPosition]="'before'">
              <p>{{"quiz.limitQuizRetakes"|translate}}</p>
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="col-md-9 mb-4" [hidden]="!f.isLimitnumberOfQuiz.value">
        <div class="mat-dialog-inner">
          <mat-form-field class="matSuffix-control">
            <mat-label>{{"quiz.maxQuizRetakes"|translate}}</mat-label>
            <input matInput formControlName="numberOfQuiz" [required]="f.isLimitnumberOfQuiz.value" appOnlynumber>
            <mat-error *ngIf="hasError('numberOfQuiz', 'required')">
              {{'forms.Required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>
  </div>
  <div class="module-content-type" *ngIf="!dataLoading">
    <app-questions-form [_formGroup]="_formGroup" [isSubmitted]="isSubmitted" [selectedType]="selectedType"
      [courseId]="courseId" [moduleId]="moduleId" [lessonId]="lessonId" [order]="order"
      [assessmentType]="assessmentType" [moduleDetails]="moduleDetails" [isSlideQuiz]="isSlideQuiz"
      [slideQuizData]="slideQuizData" [moduleLessonDetail]="moduleLessonDetail">
      <h3 *ngIf=" isSlideQuiz" class="subtitle-form">{{"quiz.questions"|translate}}</h3>
      <div class="title" *ngIf="!isSlideQuiz">
        <h2>{{"quiz.questions"|translate}}</h2>
      </div>
    </app-questions-form>
  </div>
  <div class="d-flex justify-content-between align-items-center" *ngIf="!isSlideQuiz">
    <button mat-raised-button color="grey" (click)="cancel()">{{"swalMsg.cancel"|translate}}</button>
    <button mat-raised-button color="primary" (click)="saveRecord()"
      [disabled]="loading || !questionsArray.controls.length" [class.loading]='loading'>
      {{"courseDetails.saveContent"|translate}}
    </button>
  </div>
</form>