import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwalService } from 'src/app/services/swal.service';

export interface DialogData {
  img: string;
  title: string;
  message: string;
  btnTitle: string;
  btnClass: string;
  btnCancelClass: string;
  btnCancel: boolean;
  btnCancelTitle: string;
  record: any;
  serviceTarget: string;
  service: any;
  hideSwalMessageSuccess: boolean;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})

export class ConfirmationDialogComponent implements OnInit {

  loading = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {
  }

  confirm(): void {
    if (this.data.serviceTarget) {
      this.loading = true;
      const service = this.data.service;
      service[this.data.serviceTarget](this.data.record).subscribe(() => {
        this.loading = false;
        if (!this.data.hideSwalMessageSuccess) {
          this.swalService.swalSuccess();
        }
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        this.handleErrors(err.detail);
      });
    } else {
      this.dialogRef.close(true);
    }
  }

  handleErrors(errors) {
    errors = JSON.parse(errors);
  }
}
