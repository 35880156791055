import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConcurelAuthUser } from '../models/concurel/ConcurelAuthUser';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { QuizzeQustion } from '../models/trainer/question-model/QustionModel';

@Injectable({
  providedIn: 'root',
})
export class TrainerService {
  refreshDetails = new BehaviorSubject<any>(null);
  activeTab = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) { }

  createCourse(model, file, isDuplicateCourse): any {
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    formData.append('data', JSON.stringify(model));
    if (!isDuplicateCourse) {
      if (model.id) {
        return this.http.put(
          `${environment.apiUrl}/Course/UpdateCourse`,
          formData
        );
      } else {
        return this.http.post(
          `${environment.apiUrl}/Course/CreateCourse`,
          formData
        );
      }
    } else {
      return this.http.post(
        `${environment.apiUrl}/Course/DuplicateCourse`,
        formData
      );
    }
  }

  getAll(filter) {
    return this.http.get(`${environment.apiUrl}/Course/FilterCourse`, {
      params: filter,
    });
  }

  getCourseDetails(id) {
    return this.http.get(
      `${environment.apiUrl}/Course/GetCourseDetails?CourseId=${id}`
    );
  }

  deleteRecord(id) {
    return this.http.delete(
      `${environment.apiUrl}/Course/DeleteCourse?CourseId=${id}`
    );
  }

  AddDescriptionOrOverViwe(model) {
    return this.http.post(
      `${environment.apiUrl}/Course/AddDescriptionOrOverViwe`,
      model
    );
  }

  addRecord(model, api) {
    if (model.id) {
      return this.http.put(`${environment.apiUrl}${api}`, model);
    } else {
      return this.http.post(`${environment.apiUrl}${api}`, model);
    }
  }

  deleteCourseOutCome(id) {
    return this.http.delete(
      `${environment.apiUrl}/CourseOutCome/DeleteCourseOutCome?CourseOutComeId=${id}`
    );
  }
  deleteCourseFAQ(id) {
    return this.http.delete(
      `${environment.apiUrl}/CourseFAQ/DeleteCourseFAQ?CourseFAQId=${id}`
    );
  }

  deleteCourseModule(id) {
    return this.http.delete(
      `${environment.apiUrl}/CourseModule/DeleteCourseModule?CourseModuleId=${id}`
    );
  }

  deleteModuleLesson(id) {
    return this.http.delete(
      `${environment.apiUrl}/CourseModule/DeleteModuleLesson?ModuleLessonId=${id}`
    );
  }

  reOrder(model, api) {
    return this.http.put(`${environment.apiUrl}${api}`, model);
  }

  getModuleById(id) {
    return this.http.get(
      `${environment.apiUrl}/CourseModule/GetModuleById?ModuleId=${id}`
    );
  }

  getModuleLessonById(id, isPreview) {
    return this.http.get(
      `${environment.apiUrl}/CourseModule/GetModuleLessonByIdNew?ModuleLessonId=${id}&IsPreview=${isPreview}`
    );
  }

  GetModuleLessonByIdNew(model) {
    return this.http.get(
      `${environment.apiUrl}/CourseModule/GetModuleLessonByIdNew`, { params: model }
    );
  }

  createModuleLesson(model, file, quizzeQustions): any {
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    } else {
      formData.append('file', '');
    }
    if (quizzeQustions && quizzeQustions.length) {
      formData.append('quizzeQustions', JSON.stringify(quizzeQustions));
    }
    formData.append('data', JSON.stringify(model));
    if (model.id) {
      return this.http.post(
        `${environment.apiUrl}/CourseModule/UpdateModuleLesson`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      );
    } else {
      return this.http.post(
        `${environment.apiUrl}/CourseModule/CreateModuleLesson`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      );
    }
  }

  createModuleLessons(formData: any, id?: any): any {
    if (id) {
      return this.http.post(
        `${environment.apiUrl}/CourseModule/UpdateModuleLessons`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      );
    } else {
      return this.http.post(
        `${environment.apiUrl}/CourseModule/CreateModuleLessons`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      );
    }
  }


  createModuleLesson_Traditional(model: any, id?: any): any {
    if (id) {
      return this.http.post(
        `${environment.apiUrl}/CourseModule/UpdateModuleLessons`,
        model
      );
    } else {
      return this.http.post(
        `${environment.apiUrl}/CourseModule/CreateModuleLessons`,
        model
      );
    }
  }

  createQuizModuleLesson(model): any {
    if (model.moduleLesson.id) {
      return this.http.put(
        `${environment.apiUrl}/CourseModule/UpdatePrePostAssessmentModuleLesson`,
        model
      );
    } else {
      return this.http.post(
        `${environment.apiUrl}/CourseModule/CreatePrePostAssessmentModuleLesson`,
        model
      );
    }
  }
  uploadFile(file: any) {
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    return this.http.post(`${environment.apiUrl}/Course/upload`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  publishCourse(data: any) {
    return this.http.put(`${environment.apiUrl}/Course/PublishCourse`, data);
  }

  listTrainerUsers() {
    return this.http.get(`${environment.apiUrl}/ManageUsers/ListTrainerUsers`);
  }
  listTraineeUsers(filter) {
    return this.http.get(`${environment.apiUrl}/ManageUsers/ListTraineeUsers`, {
      params: filter,
    });
  }

  enrollmentTrainee(model) {
    return this.http.post(
      `${environment.apiUrl}/Course/EnrollmentTrainee`,
      model
    );
  }
  EnrollmentTraineeList(model) {
    return this.http.post(
      `${environment.apiUrl}/Course/EnrollmentTraineeList`,
      model
    );
  }

  removeEnrollmentTrainee(id) {
    return this.http.delete(
      `${environment.apiUrl}/Course/RemoveEnrollmentTrainee?EnrollmentId=${id}`
    );
  }
  getTraineeDetails(filter) {
    return this.http.get(`${environment.apiUrl}/Course/GetTraineeDetails`, {
      params: filter,
    });
  }

  unPublishCourse(id) {
    return this.http.put(`${environment.apiUrl}/Course/UnPublishCourse?CourseId=${id}`, null);
  }

  getEssayQuestionPerModule(params: any) {
    return this.http.get(`${environment.apiUrl}/Course/GetEssayQuestionPerModule`, { params });
  }

  setTrainerScorePerQuestion(data: any) {
    return this.http.post(`${environment.apiUrl}/Course/SetTrainerScorePerQuestion`, data);
  }
  filterQuestionsAutomaticall(data: any) {
    return this.http.post(`${environment.apiUrl}/QuestionBankQuestion/FilterQuestionsAutomatically`, data);
  }

  getQuestionsFromQuestionBanks(params: any) {
    return this.http.get(`${environment.apiUrl}/QuestionBankQuestion/FilterQuestionsLists`, { params });
  }

  // LookUps
  getDifficulty_LookUPs() {
    return this.http.get(`${environment.apiUrl}/Lookups/GetQuestionBankDifficulties`);
  }
  getQuestionType_LookUPs() {
    return this.http.get(`${environment.apiUrl}/Lookups/GetAllQuizQuestionType`);
  }
  getCategory_LookUPs() {
    return this.http.get(`${environment.apiUrl}/Lookups/GetQuestionBankCategories`);
  }
  getTags_LookUPs() {
    return this.http.get(`${environment.apiUrl}/Lookups/ListMyTags`);
  }
  getQuestionBanks_LookUPs() {
    return this.http.get(`${environment.apiUrl}/Lookups/ListMyQuestionBanks`);
  }

  getAssignmentProgressDetails(params: any) {
    return this.http.get(`${environment.apiUrl}/Course/GetAssignmentProgressDetails`, { params });
  }

  setTrainerScorePerAssignment(data: any) {
    return this.http.post(`${environment.apiUrl}/Course/SetTrainerScorePerAssignment`, data);
  }
}
