<div
  class="app-mian-header {{ (currentUser && currentUser.ForTrainning && !currentUser.IsTrainer) || isPreview?'bg_gradient_purple':''}} {{customClass}}">
  <div class="header-inner">
    <ng-container *ngIf="!forExtract && !isPreview; else forlayout">
      <div class="menu-toggle" (click)="toggelSidebar()">
        <img src="assets/images/icons/minimize-sidebar-icon.svg" alt="">
      </div>
      <div class="logo-mobile">
        <img src="./assets/images/logo/smallLogo.svg">
      </div>
    </ng-container>
    <ng-template #forlayout>
      <div class="logo-forextract">
        <img src="./assets/images/logo/histo-app-logo.png">
      </div>
    </ng-template>

    <div class="header-navigation"
      *ngIf="showNavigation && currentUser && currentUser.ForTrainning && !currentUser.IsTrainer">
      <ul>
        <li>
          <a [routerLink]="['/admin/courses']" routerLinkActive="active">{{"sideBar.courses"|translate}}</a>
        </li>
        <li>
          <a [routerLink]="['/admin/cases/slideCases']" routerLinkActive="active">{{"sideBar.slides"|translate}}</a>
        </li>
      </ul>
    </div>
    <div class="header-actions" *ngIf="!currentUser?.ForTrainning">
      <a class="btn change-language" (click)="changeLanguage()">
        <!-- <img src="./assets/images/icons/language-icon.svg" alt=""> -->
        {{ 'general.language' | translate }}
      </a>
    </div>
    <div class="header-part-right">
      <a class="btn change-language mx-2" *ngIf="currentUser?.ForTrainning" (click)="changeLanguage()">
        <!-- <img src="./assets/images/icons/language-icon.svg" alt=""> -->
        {{ 'general.language' | translate }}
      </a>
      <div class="elementor-widget-container"
        *ngIf="(hasPermission('Pathologist') || hasPermission('VLPathologist')) && currentUser && !currentUser.ForTrainning">
        <div class="notification-btn dropdown-container" appDropDown>
          <span class="dropdown-toggler not-after">
            <span class="notification-count">{{ nmessagesList.length }}</span>
            <img src="./assets/images/icons/chat.svg">
          </span>
          <div class="notification-panel dropdown-inner-menu">
            <div class="notification-header">
              <h3>Messages</h3>
            </div>

            <div *ngIf='messagesLoading' class="dataLoading">
              <mat-spinner class="m-auto" diameter="50"></mat-spinner>
            </div>
            <div [hidden]='messagesLoading'>
              <div class="no-data text-center px-2 py-5" *ngIf="!nmessagesList.length">
                You have no Messages
              </div>
              <div class="notification-list" *ngIf="nmessagesList.length">
                <div *ngFor="let message of nmessagesList">
                  <a class="message_user_item"
                    [routerLink]="['/admin/cases/details', message.caseID, message.caseIdType]"
                    [queryParams]="{ chatUserId: message.fromId }">
                    <div class="inner_message_item">
                      <div class="img-circle">
                        <div class="image m-0">
                          <img *ngIf="message.image" [src]="message.image" (error)='message.image = ""'>
                          <span *ngIf="!message.image">{{ message.name | shortName }}</span>
                        </div>
                      </div>
                      <div class="name">
                        <h5>{{message.name}}</h5>
                        <div>
                          <span>{{message.message | truncateText:80}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-center border-0">
                      <span class="date_time d-block">
                        {{message.messageTime | date:'d MMM, y'}}
                      </span>
                      <span class="date_time d-block">
                        {{message.messageTime | date:'h:mm a'}}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-widget-container" *ngIf="!hasPermission('Trainer')">
        <div class="notification-btn dropdown-container" appDropDown>
          <span (click)="openNotificationPanel()" class="dropdown-toggler not-after">
            <span class="notification-count">{{ notificationsResult.unSeenNotificationsCount }}</span>
            <!-- <img src="./assets/images/icons/notifications-icon.svg"> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21"
              [ngClass]="{swing: notificationsResult.unSeenNotificationsCount}">
              <path id="notifications-icon"
                d="M269.347,364.745l-1.974-1.955v-3.331a7.314,7.314,0,0,0-6.575-7.241v-1.449h-1.462v1.449a7.374,7.374,0,0,0-6.576,7.241v3.331l-1.973,1.955a.66.66,0,0,0-.219.507v2.172a.687.687,0,0,0,.731.725h5.115a3.653,3.653,0,0,0,7.306,0h5.115a.684.684,0,0,0,.73-.634.7.7,0,0,0,0-.091v-2.172A.656.656,0,0,0,269.347,364.745Zm-9.28,5.576a2.182,2.182,0,0,1-2.192-2.172h4.384a2.182,2.182,0,0,1-2.192,2.172ZM268.1,366.7H252.03v-1.158L254,363.586a.657.657,0,0,0,.219-.506v-3.621a5.845,5.845,0,0,1,11.69,0v3.621a.657.657,0,0,0,.219.506l1.973,1.956Z"
                transform="translate(-250.566 -350.769)" fill="gray" />
            </svg>

          </span>
          <div class="notification-panel dropdown-inner-menu">
            <div class="notification-header">
              <h3>{{ 'header.Notification' | translate }}</h3>
              <a [routerLink]="['/admin/notification-preference']" mat-button color="primary"
                class="btn_link_with_icon p-0 mx-2">
                <img src="./assets/images/icons/Notification-Preferences.svg">
                <span class="ml-1">
                  {{ 'general.preferences' | translate }}
                </span>
              </a>
            </div>

            <div>
              <div class="no-data text-center px-2 py-5" *ngIf="!notificationsList.length">
                {{ 'header.You have no notifications' | translate }}!
              </div>
              <div class="notification-list" *ngIf="notificationsList.length" infiniteScroll [scrollWindow]="false"
                [infiniteScrollDistance]="2" [infiniteScrollThrottle]="pageCount" (scrolled)="getNextPage()">
                <div *ngFor="let notif of notificationsList" [ngClass]="notif.isSeen ? '':'new'">
                  <ng-container [ngSwitch]="notif.notificationType">
                    <a *ngSwitchCase="'LAB_UPDATE'" (click)="getUnSeeNotifications(notif,['/admin/labProfile'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_QUALITY'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId],{isForQuality: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REF_CASE_UPDATE'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId,'RefCase'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REF_CASE_UPDATE_GROSSING'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId,'RefCase'],{isForGrossing: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REF_CASE_UPDATE_QUALITY'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId,'RefCase'],{isForQuality: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_SECOND_OPINION'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_SENT_BACK'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId],{requestId:notif.param3En})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_PATHOLOGIST'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId],{userId:notif.param4, userType:notif.param5 })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_REF_UPDATE_PATHOLOGIST'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId,'RefCase'],{userId:notif.param4, userType:notif.param5 })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_ENDORSEMENT'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId],{userId:notif.param4, userType:notif.param5, IsEndorsement: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REF_CASE_UPDATE_ENDORSEMENT'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId,'RefCase'],{userId:notif.param4, userType:notif.param5, IsEndorsement: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_CANCELLED'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_GROSSING'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId],{isForGrossing: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications SLA Histo -->
                    <a *ngSwitchCase="'REJECTED_SLA'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntity(notif.param2).value + '/preview-sla/' + notif.param4])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'ADDED_SLA'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntity(notif.param2).value + '/preview-sla/' + notif.param3 +'/true'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'TERMINTED_SLA'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntity(notif.param2).value + '/preview-sla/' + notif.objectId +'/true'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'UPDATED_SLA'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntity(notif.param2).value + '/preview-sla/' + notif.param3 +'/true'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications SLA With Entities -->
                    <a *ngSwitchCase="'REJECTED_SLA_CONTRACT'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntityType(notif.param2,notif.notificationType) + '/preview-sla/' + notif.param4])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'ADDED_SLA_CONTRACT'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntityType(notif.param1,notif.notificationType) + '/preview-sla/' + notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'TERMINATED_SLA_CONTRACT'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntityType(notif.param2,notif.notificationType) + '/preview-sla/' + notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'UPDATED_SLA_CONTRACT'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntityType(notif.param1,notif.notificationType) + '/preview-sla/' + notif.param3])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications Pathologist Request -->
                    <a *ngSwitchCase="'REGISTERATION'"
                      (click)="getUnSeeNotifications(notif,['/admin/Pathologists/Requests'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications invoice -->
                    <!-- <a *ngSwitchCase="'REGISTERATION'"
                      (click)="getUnSeeNotifications(notif,['/admin/Pathologists/Requests'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a> -->

                    <!-- Notifications Requests -->
                    <a *ngSwitchCase="'REQUEST_UPDATED'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'ACCEPT_REQUEST'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <a *ngSwitchCase="'WAITING_PICKUP'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId],{model: deliverPickup.PickupAndDelivery, requestType : requestType.PICKUP })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'WAITING_PICKUP_CONTAINER'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId],{model: deliverPickup.PickupAndDelivery, requestType : requestType.PICKUP })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'WAITING_DELIVERY'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId],{model: deliverPickup.PickupAndDelivery, requestType : requestType.DELIVERY })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'WAITING_DELIVERY_CONTAINER'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId],{model: deliverPickup.PickupAndDelivery, requestType : requestType.DELIVERY })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'WAITING_CONTAINER_DELIVERY'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId],{model: deliverPickup.PickupAndDelivery, requestType : requestType.DELIVERY })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REMOVE_PICKUP_REPRESENTATIVE'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REMOVE_PICKUP_CONTAINER_REPRESENTATIVE'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REMOVE_DELIVERY_REPRESENTATIVE'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REMOVE_DELIVERY_CONTAINER_REPRESENTATIVE'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications Payment -->
                    <a *ngSwitchCase="'MARK_PAID'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/'+ (hasPermission('VLAdmin') || hasPermission('VLAccountant')?'VLTransactions':'incoming-outgoing')],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'MARK_UNPAID'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/'+ (hasPermission('VLAdmin') || hasPermission('VLAccountant')?'VLTransactions':'incoming-outgoing')],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CHECKOUT'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/'+ (hasPermission('VLAdmin') || hasPermission('VLAccountant')?'VLTransactions':'incoming-outgoing')],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'MARK_PAID_HISTO'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/HistoFeesTransactions'],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'MARK_UNPAID_HISTO'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/HistoFeesTransactions'],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CHECKOUT_HISTO'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/HistoFeesTransactions'],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications Payment -->
                    <a *ngSwitchCase="'SCORE_ASSIGNMENT'"
                      (click)="getUnSeeNotifications(notif,['/admin/courses',notif.objectId],{nextModuleLessonId:notif.param2En})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                  </ng-container>
                </div>
              </div>
              <div *ngIf='notifLoading' class="dataLoading py-1">
                <mat-spinner class="m-auto" diameter="50"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-widget-container">
        <div *ngIf="currentUser" class="userAccount dropdown-container m-0" id="userAccountDrop" appDropDown>
          <div class="dropdown-toggler d-flex align-items-center">
            <div class="profile-image">
              <img *ngIf="currentUser.ImagePath" [src]="currentUser.ImagePath" (error)="currentUser.ImagePath = ''" />
              <span *ngIf="!currentUser.ImagePath" [innerHTML]="currentUser.FullName | shortName"></span>
            </div>
            <div class="user-name">
              <span class="mr-2"> {{ 'header.Hello' | translate }}, {{currentUser.FullName|titlecase}}</span>
            </div>
          </div>

          <div class="custom-dropdown-menu dropdown-inner-menu">
            <ul>
              <li *ngIf="!hasPermission('Trainer')">
                <a [routerLink]="['/admin/myprofile']">
                  <svg width="14" height="17" viewBox="0 0 14 17">
                    <path id="my-profile-icon"
                      d="M7,8.5a12.35,12.35,0,0,1,5,1c1.3.6,2,1.4,2,2.4v1.7H0V11.9A2.954,2.954,0,0,1,2,9.5,12.35,12.35,0,0,1,7,8.5ZM2.6,15.3H4.4V17H2.6ZM7,0A3.74,3.74,0,0,1,9.5,1a2.965,2.965,0,0,1,1,2.4,2.965,2.965,0,0,1-1,2.4A3.74,3.74,0,0,1,7,6.8a3.317,3.317,0,0,1-2.5-1,3.1,3.1,0,0,1-1-2.4A2.965,2.965,0,0,1,4.5,1,3.74,3.74,0,0,1,7,0ZM6.1,15.3H7.8V17H6.1Zm3.5,0h1.8V17H9.6Z"
                      fill="#999999" />
                  </svg>{{ 'header.Edit-Profile' | translate }}
                </a>
              </li>
              <li *ngIf="currentUser && !currentUser.ForTrainning">
                <a [routerLink]="['/admin/configuration/media-center']">
                  <svg width="14" height="17" viewBox="0 0 17 17">
                    <path d="M1 4v6h15v-6h-15zM15 9h-13v-4h13v4zM14 11v1h-11v-1h11zM2 13h13v1h-13v-1z" fill="#999999" />
                  </svg>{{ 'header.media_center' | translate }}
                </a>
              </li>
              <li hidden><a [routerLink]="['changepassword']">
                  <svg width="14" height="17" viewBox="0 0 14 17">
                    <path id="my-profile-icon"
                      d="M7,8.5a12.35,12.35,0,0,1,5,1c1.3.6,2,1.4,2,2.4v1.7H0V11.9A2.954,2.954,0,0,1,2,9.5,12.35,12.35,0,0,1,7,8.5ZM2.6,15.3H4.4V17H2.6ZM7,0A3.74,3.74,0,0,1,9.5,1a2.965,2.965,0,0,1,1,2.4,2.965,2.965,0,0,1-1,2.4A3.74,3.74,0,0,1,7,6.8a3.317,3.317,0,0,1-2.5-1,3.1,3.1,0,0,1-1-2.4A2.965,2.965,0,0,1,4.5,1,3.74,3.74,0,0,1,7,0ZM6.1,15.3H7.8V17H6.1Zm3.5,0h1.8V17H9.6Z"
                      fill="#999999" />
                  </svg>{{ 'auth.Update-password' | translate }}
                </a></li>
              <li>
                <a (click)="logout()">
                  <svg width="17.001" height="17.006" viewBox="0 0 17.001 17.006">
                    <path id="logout-icon"
                      d="M6223.19,12103h-13.4a1.815,1.815,0,0,1-1.8-1.808v-13.383a1.815,1.815,0,0,1,1.811-1.808h13.4a1.817,1.817,0,0,1,1.792,1.808v4.146l-1.659-1.559v-2.74h-13.687v13.575h13.687v-2.625l1.659-1.461v4.165a1.766,1.766,0,0,1-.529,1.267,1.69,1.69,0,0,1-1.123.43C6223.3,12103.006,6223.243,12103,6223.19,12103Zm-4.514-5.039,2.691-2.688h-9.562v-1.54h9.562l-2.691-2.691,1.167-1.169,4.52,4.629-4.52,4.629Z"
                      transform="translate(-6208 -12086)" fill="#999" />
                  </svg>{{ 'header.SignOut' | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>