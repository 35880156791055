import { AuthenticationService } from 'src/app/services/authentication.service';
import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsSearchModel } from 'src/app/models/notification/NotificationsSearchModel';
import { AuthUser } from 'src/app/models/auth/AuthUser';
import { NavigationService } from 'src/app/services/navigation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { SignalRService } from 'src/app/services/signal-rservice.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ChatService } from 'src/app/services/chat.service';
import { EntityEnumSLA } from 'src/app/models/sla/enums/EntityEnumSLA';
import { ContractType } from 'src/app/models/sla/enums/ContractType';
import { ModelsEnumRequests } from 'src/app/models/requests/enums/ModelRequests';
import { RequestType } from 'src/app/models/requests/requestType';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isPreview: boolean = false;
  @Input() forExtract = true;
  @Input() customClass = '';
  @Input() showMenu = false;
  @Input() showNavigation = true;
  isArabic = false;
  @Output() sidebarTrigger = new EventEmitter();
  notificationNumber: number;
  currentUser = new AuthUser();
  notifLoading = false;
  messagesLoading = false;
  pageNumber = 1;
  pageSize = 10;
  pageCount: number;
  notificationsList: Array<Notification> = [];
  nmessagesList: [] = [];
  NotificationsObject: NotificationsSearchModel = new NotificationsSearchModel();
  activeAlerts = {};
  notificationsResult: any = {
    unSeenNotificationsCount: 0,
    totalCount: 0,
  };

  models = [
    { name: 'Hospital', value: 'hospital', key: EntityEnumSLA.hospital },
    { name: 'Clinic', value: 'clinic', key: EntityEnumSLA.clinic },
    { name: 'Lab', value: 'lab', key: EntityEnumSLA.lab },
    { name: 'Scanning Center', value: 'scanningCenter', key: EntityEnumSLA.scanningCenter }
  ];

  deliverPickup = ModelsEnumRequests;
  requestType = RequestType;

  constructor(
    public zone: NgZone,
    private router: Router,
    public translate: TranslateService,
    private signalRService: SignalRService,
    private snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    public dialogRef: MatDialog,
    public navService: NavigationService,
    public chatService: ChatService,
    private translationService: TranslationService,
    private authenticationService: AuthenticationService
  ) {
    // this.currentUser = this.authenticationService.currentUserValue;
    this.getNotifications(false);
    this.getMessages();
    this.signalRService.startConnection();
    this.signalRService.changeNotificationCount(this, this.updateNotification);
    this.signalRService.handleNewChatMessageEvent(this, this.updateMessages);
    this.signalRService.handleSignOutEvent(this, this.autoLogout);
  }


  autoLogout(context, userId) {
    context.zone.run(() => {
      context.authenticationService.logout();
    });
  }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(user => {
      this.currentUser = user;
    });
    this.chatService.messagesList.subscribe(res => {
      if (res) {
        this.getMessages();
      }
    });
  }
  // SignalR
  updateNotification(context) {
    context.zone.run(() => {
      context.getNotifications(true);
      context.openSnackBar('You have a new notification');
    });
  }

  updateMessages(context) {
    context.zone.run(() => {
      context.getMessages();
      context.openSnackBar('You have a new message');
    });
  }

  openSnackBar(msg) {
    this.snackBar.open(msg, '✖', { duration: 10000, horizontalPosition: 'end', verticalPosition: 'bottom' });
  }

  // Notifications
  openNotificationPanel() {
    // this.notificationsResult.unSeenNotificationsCount = 0;
    // this.getUnSeeNotifications();
  }

  getUnSeeNotifications(notification, url, params = null) {
    if (!notification.isSeen) {
      const param = { NotificationID: notification.notificationId };
      this.notificationsService.SeeNotifications(param).subscribe(
        result => {
          // this.notificationsResult.unSeenNotificationsCount = result;
          this.getNotifications(true);
          return this.router.navigate(url, { queryParams: params });
        }, err => {
          console.error(err);
        });
    } else {
      return this.router.navigate(url, { queryParams: params });
    }
  }
  getNextPage() {
    if (this.pageNumber < this.pageCount - 1) {
      this.pageNumber += 1;
      this.getNotifications(false);
    }
  }
  getNotifications(update) {
    if (update) {
      this.pageNumber = 1;
      this.notificationsList = [];
    }
    this.notifLoading = true;
    this.NotificationsObject.pageSize = this.pageSize;
    this.NotificationsObject.pageNumber = this.pageNumber;

    this.notificationsService.GetNotifications(this.NotificationsObject).subscribe(
      result => {
        this.notificationsResult = result as any;
        this.pageCount = Math.ceil(this.notificationsResult.totalCount / this.pageSize);
        if (this.notificationsResult.pageData && this.notificationsResult.pageData.length) {
          this.notificationsList = this.notificationsList.concat(this.notificationsResult.pageData);
        }
        this.notifLoading = false;
      }, err => {
        this.notifLoading = false;
        console.error(err);
      });
  }

  // Messages
  getMessages() {

    this.chatService.GetMissedConversations().subscribe(
      result => {
        this.messagesLoading = false;
        this.nmessagesList = result as any;
      }, err => {
        this.messagesLoading = false;
      });
  }

  // Actions
  changeLanguage() {
    if (localStorage.getItem('language') === 'ar') {
      this.translationService.setLanguage('en');
    } else {
      this.translationService.setLanguage('ar');
    }
    window.location.reload();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
  }

  hasPermission(permission: string) {
    return this.authenticationService.hasPermission(permission);
  }

  getEntity(key) {
    return this.models.find(item => item.key === key);
  }

  getEntityType(key: ContractType, notifType: string): string {
    let module = null;
    if (
      (
        (
          key === ContractType.SC_LAB ||
          key === ContractType.SC_VL
        ) &&
        (
          notifType === 'REJECTED_SLA_CONTRACT' ||
          (
            notifType === 'TERMINATED_SLA_CONTRACT' &&
            this.hasPermission(`${RolesEnum.ScanningCenterAdmin},${RolesEnum.ScanningCenterAccountant}`)
          )
        )
      ) ||
      (
        (
          key === ContractType.VL_CLINICS ||
          key === ContractType.LAB_CLINICS ||
          key === ContractType.LAB_HOSPITALS ||
          key === ContractType.VL_HOSPITALS
        ) &&
        (
          notifType === 'ADDED_SLA_CONTRACT' ||
          notifType === 'UPDATED_SLA_CONTRACT' ||
          (
            notifType === 'TERMINATED_SLA_CONTRACT' &&
            this.hasPermission(`${RolesEnum.HospitalAdmin},${RolesEnum.HospitalAccountant},${RolesEnum.ClinicAdmin},${RolesEnum.ClinicAccountant}`)
          )
        )
      )
    ) {
      module = 'labs';
    } else if (
      (
        (
          key === ContractType.LAB_LAB ||
          key === ContractType.LAB_VL ||
          key === ContractType.VL_LAB
        ) &&
        (
          notifType === 'ADDED_SLA_CONTRACT' ||
          notifType === 'UPDATED_SLA_CONTRACT' ||
          notifType === 'REJECTED_SLA_CONTRACT' ||
          notifType === 'TERMINATED_SLA_CONTRACT'
        )
      )
    ) {
      module = 'otherlabs';
    } else if (
      (
        key === ContractType.VL_PATH ||
        key === ContractType.LAB_PATH
      ) &&
      (
        notifType === 'ADDED_SLA_CONTRACT' ||
        notifType === 'UPDATED_SLA_CONTRACT' ||
        notifType === 'REJECTED_SLA_CONTRACT' ||
        notifType === 'TERMINATED_SLA_CONTRACT'
      )
    ) {
      module = 'pathologists';
    } else if (
      (
        key === ContractType.SC_CLINICS ||
        key === ContractType.SC_HOSPITALS ||
        key === ContractType.SC_LAB ||
        key === ContractType.SC_VL
      ) &&
      (
        notifType === 'ADDED_SLA_CONTRACT' ||
        notifType === 'UPDATED_SLA_CONTRACT' ||
        (
          notifType === 'TERMINATED_SLA_CONTRACT' &&
          (
            this.hasPermission(`${RolesEnum.HospitalAdmin},${RolesEnum.HospitalAccountant},${RolesEnum.ClinicAdmin},${RolesEnum.ClinicAccountant}`) ||
            this.hasPermission(`${RolesEnum.LabAdmin},${RolesEnum.LabAccountant},${RolesEnum.VLAdmin},${RolesEnum.VLAccountant}`)
          )
        )
      )
    ) {
      module = 'scanningCenters';
    } else if (
      (
        key === ContractType.SC_HOSPITALS ||
        key === ContractType.SC_CLINICS ||
        key === ContractType.VL_HOSPITALS ||
        key === ContractType.VL_CLINICS ||
        key === ContractType.LAB_HOSPITALS ||
        key === ContractType.LAB_CLINICS
      ) &&
      (
        notifType === 'REJECTED_SLA_CONTRACT' ||
        (
          notifType === 'TERMINATED_SLA_CONTRACT' &&
          this.hasPermission(`${RolesEnum.ScanningCenterAdmin},${RolesEnum.ScanningCenterAccountant}`) ||
          this.hasPermission(`${RolesEnum.LabAdmin},${RolesEnum.LabAccountant},${RolesEnum.VLAdmin},${RolesEnum.VLAccountant}`)
        )
      )
    ) {
      module = 'hospitalsclinics';
    }
    return module;
  }

  ngOnDestroy(): void {

  }

}
