import { TranslateService } from '@ngx-translate/core';
import { ModelsEnum } from 'src/app/models/users/users/enums/ModelsEnum';
import { ScanningCentersService } from 'src/app/services/scanningCenters.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDetails } from 'src/app/models/users/users/UserDetails';
import { Lookup } from 'src/app/models/lookups/Lookup';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { LabsService } from 'src/app/services/labs.service';
import { LookupsService } from 'src/app/services/lookups.service';
import { UsersService } from 'src/app/services/users.service';
import { UserForm } from 'src/app/models/users/users/UserForm';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { CoveredArea } from 'src/app/models/users/CoveredArea';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Availability } from 'src/app/models/users/users/availability';
import { RolesService } from 'src/app/services/roles.service';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';
import { SwalService } from 'src/app/services/swal.service';
import { AvailabilityData } from 'src/app/models/users/users/availabilityData';

export interface DialogData {
  record: UserDetails;
  userId: number;
  labId: number;
  scId: number;
  hospitalId: number;
  clinicId: number;
  model: string; // LAB, Sc, VL
  isModuleAdmin: boolean;
  makeSearchForPathologist: boolean;
  pathologistLabType: string; // VIRTUAL_LAB, All, LAB
  disableInpusForUpdate: boolean;
  isTrainer: boolean;
}
@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
})
export class UserFormComponent implements OnInit {
  hidePassword = true;
  userId: number;
  labId: number;
  scId: number;
  hospitalId: number;
  clinicId: number;
  formGroup: FormGroup;
  dataLoading = false;
  loading = false;
  bindLoading = false;
  model: string;
  details: UserDetails;
  isSubmitted = false;
  isValidTimeList = true;

  mainLabsList: Lookup[] = [];
  entityList: Lookup[] = [];
  scList: Lookup[] = [];
  countryList: Lookup[] = [];
  idTypesList: Lookup[] = [];
  regionList: Lookup[] = [];
  cityList: Lookup[] = [];
  specialtyList: Lookup[] = [];
  subspecialtieList: Lookup[] = [];
  positionList = [];
  reviewerList: Lookup[] = [];

  roles = [];
  disableAll = false;
  availabilityList: Availability[] = JSON.parse(
    JSON.stringify(AvailabilityData)
  );

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  selectedCountryISO: string = CountryISO.SaudiArabia;

  citiesTree: Lookup[] = [];
  detailsSelectedCities: any;
  selectedCities = [];
  loadingCA = false;
  loadingCAInput = false;
  regionLoading = false;
  regionInputLoading = false;
  citiesLoading = false;
  citiesInputLoading = false;

  activeAreaIndex = 0;
  coveredAreaList: CoveredArea[] = [new CoveredArea()];

  isPathologist = false;

  constructor(
    public dialogRef: MatDialogRef<UserFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private service: UsersService,
    private serviceLabs: LabsService,
    private lookupsService: LookupsService,
    private rolesService: RolesService,
    private scService: ScanningCentersService,
    private auth: AuthenticationService,
    public translate: TranslateService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    console.log(this.data);

    // alert(this.data.disableInpusForUpdate);
    this.model = this.data.model;
    if (
      this.model === 'PATHOLOGIST_USER' ||
      (this.data &&
        this.data.model === ModelsEnum.profile &&
        this.data.record.userType === 'PATHOLOGIST_USER')
    ) {
      this.isPathologist = true;
    }
    // model == 'PATHOLOGIST_USER'   => getLabAndVlLabList

    if (
      (this.data && this.data.model === ModelsEnum.Lab && this.data.labId) ||
      this.data.pathologistLabType === 'LAB'
    ) {
      this.labId = this.data.labId;
    }
    if (
      this.data &&
      this.data.model === ModelsEnum.ScanningCenter &&
      this.data.scId
    ) {
      this.scId = this.data.scId;
    }
    if (
      this.data &&
      this.data.model === ModelsEnum.Hospital &&
      this.data.hospitalId
    ) {
      this.hospitalId = this.data.hospitalId;
    }
    if (
      this.data &&
      this.data.model === ModelsEnum.Clinic &&
      this.data.clinicId
    ) {
      this.clinicId = this.data.clinicId;
    }

    if (this.data.isModuleAdmin) {
      if (!this.labId) {
        this.labId = 0;
      }
      if (
        !this.data.scId &&
        !(this.data.record && this.data.record.scanningCenter?.id)
      ) {
        this.scId = 0;
        this.getSCCoverageAreas(true);
      }
      if (!this.data.hospitalId) {
        this.hospitalId = 0;
      }
      if (!this.data.clinicId) {
        this.clinicId = 0;
      }
    }

    this.getAllMain();
    this.getScaningCenter();
    this.getAllEntites();
    this.getCountries();
    this.getIdTypes();
    this.getRoles();
    this.reportToRole();
    this.getSubspecialties();

    this.buildForm();

    if (this.data && this.data.record) {
      this.userId = this.data.userId;
      this.bindDataToUpdate();
    }

    // this.formGroup.controls.contactNumber.valueChanges.subscribe(() => {
    //   this.checkUserFound();
    // });
  }

  disableAllFields() {
    this.formGroup.controls.identityType.disable();
    this.formGroup.controls.identityId.disable();
    this.formGroup.controls.contactNumber.disable();
    this.formGroup.controls.firstName.disable();
    this.formGroup.controls.lastName.disable();
    this.formGroup.controls.email.disable();
    this.formGroup.controls.canShareToConCural.disable();
    this.formGroup.controls.country.disable();
    this.formGroup.controls.region.disable();
    this.formGroup.controls.cityId.disable();
    this.formGroup.controls.address.disable();
    this.formGroup.controls.cities.disable();
    this.formGroup.controls.subspecialties.disable();
    // this.disableAll = true
  }
  enableAllFields() {
    this.formGroup.controls.identityType.enable();
    this.formGroup.controls.identityId.enable();
    this.formGroup.controls.contactNumber.enable();
    this.formGroup.controls.firstName.enable();
    this.formGroup.controls.lastName.enable();
    this.formGroup.controls.email.enable();
    this.formGroup.controls.canShareToConCural.enable();
    this.formGroup.controls.country.enable();
    this.formGroup.controls.region.enable();
    this.formGroup.controls.cityId.enable();
    this.formGroup.controls.address.enable();
    this.formGroup.controls.cities.enable();
    this.formGroup.controls.subspecialties.enable();
    // this.disableAll = false
  }
  clearAllFields() {
    this.formGroup.controls.identityType.setValue(null);
    this.formGroup.controls.identityId.setValue(null);
    this.formGroup.controls.identityType.markAsUntouched();
    this.formGroup.controls.identityId.markAsUntouched();
    this.formGroup.controls.contactNumber.setValue(null);
    this.formGroup.controls.firstName.setValue(null);
    this.formGroup.controls.lastName.setValue(null);
    this.formGroup.controls.email.setValue(null);
    this.formGroup.controls.country.setValue(null);
    this.formGroup.controls.region.setValue(null);
    this.formGroup.controls.cityId.setValue(null);
    this.formGroup.controls.address.setValue(null);
    this.formGroup.controls.cities.setValue(null);
    this.formGroup.controls.subspecialties.setValue(null);
    this.availabilityList = JSON.parse(JSON.stringify(AvailabilityData));
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      lab: [this.labId],
      scanningCenterId: [this.scId],
      hospitalId: [this.hospitalId],
      clinicId: [this.clinicId],
      firstName: [
        null,
        [CustomValidator.noWhiteSpace, Validators.maxLength(50)],
      ],
      lastName: [
        null,
        [CustomValidator.noWhiteSpace, Validators.maxLength(50)],
      ],
      contactNumber: [null, [Validators.required]],
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            '[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'
          ),
        ],
      ],
      canShareToConCural: [false],
      conCuralUserName: [
        '',
        [Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9._@-]+$')],
      ],
      conCuralPassword: [''],
      country: [null],
      region: [null],
      cityId: [null],
      address: [null, [Validators.maxLength(500)]],
      role: [null],
      cities: [null],
      subspecialties: [null],
      position: [null],
      reviewer: [null],
      canSendToSO: [false],
      HasReviewer: [this.isPathologist && this.data && !this.data.isTrainer],

      identityType: [null],
      OtherIdentityType: [null],
      identityId: [null],
      scfhs: [null],
    });
    // this.formGroup.reset();
  }

  checkCanShareToConCural() {
    if (
      this.data.model == 'PATHOLOGIST_USER' ||
      (this.isPathologist &&
        this.data.record &&
        this.data.record.userStatus == 'NOT_VERIFIED')
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkCoverageAreas() {
    if (
      this.model === ModelsEnum.ScanningCenter ||
      this.model === ModelsEnum.SCUser
    ) {
      if (
        this.checkRole('role', 'ScanningCenterTechnician') ||
        this.checkRole('role', 'ScanningCenterRepresentative')
      ) {
        return true;
      }
      // if (
      //   this.checkRole('role', 'LabTechnician')
      //   || this.checkRole('role', 'ScanningCenterTechnician')
      // ) {
      //   return true;
      // }
    }
    return false;
  }
  /////////////////////////////////////

  getAllMain() {
    this.serviceLabs.getAllLabsLookup().subscribe(
      (data) => {
        this.mainLabsList = data as [];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getAllEntites() {
    // HOSPITAL or CLINIC
    let EntityType = null;
    if (
      this.model === ModelsEnum.hospitalUser ||
      this.model === ModelsEnum.Hospital
    ) {
      EntityType = 'HOSPITAL';
    } else if (
      this.model === ModelsEnum.clinicUser ||
      this.model === ModelsEnum.Clinic
    ) {
      EntityType = 'CLINIC';
    }
    this.lookupsService.getAllEntites(EntityType).subscribe(
      (data) => {
        this.entityList = data as [];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getScaningCenter() {
    this.scService.getAllScanningCentersLookup().subscribe(
      (data) => {
        this.scList = data as [];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getRoles() {
    let modelType = this.model;
    if (this.model === ModelsEnum.profile) {
      if (
        this.data.record.roles.includes(RolesEnum.ScanningCenterAdmin) ||
        this.data.record.roles.includes(RolesEnum.ScanningCenterMainAdmin) ||
        this.data.record.roles.includes(RolesEnum.SCTechnician)
      ) {
        modelType = ModelsEnum.SCUser;
      } else if (
        this.data.record.roles.includes(RolesEnum.VLAdmin) ||
        this.data.record.roles.includes(RolesEnum.VLMatchMaker) ||
        this.data.record.roles.includes(RolesEnum.VLPathologist) ||
        this.data.record.roles.includes(RolesEnum.VLTechnician) ||
        this.data.record.roles.includes(RolesEnum.VLSupervisor)
      ) {
        modelType = ModelsEnum.VLUser;
      }
    }
    this.rolesService.getAll(modelType).subscribe(
      (data) => {
        this.roles = data as [];
        this.roles.map((role) => {
          role.disabled = false;
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  checkRoles(event) {
    // console.log(event.target.parentNode.innerText);
    // tslint:disable-next-line: max-line-length
    if (
      this.formGroup.controls.role.value.includes(RolesEnum.Pathologist) ||
      this.formGroup.controls.role.value.includes(RolesEnum.VLPathologist)
    ) {
      if (this.formGroup.controls.role.value.includes(RolesEnum.Pathologist)) {
        this.formGroup.controls.role.setValue(['Pathologist']);
      } else {
        this.formGroup.controls.role.setValue(['VLPathologist']);
      }
      this.roles.map((role) => {
        if (role.value !== 'Pathologist' && role.value !== 'VLPathologist') {
          role.disabled = true;
        }
      });
      if (this.disableAll) {
        this.disableAllFields();
      }
    } else {
      if (this.formGroup.controls.role.value.length) {
        this.roles.map((role) => {
          if (role.value === 'Pathologist' || role.value === 'VLPathologist') {
            role.disabled = true;
          } else {
            role.disabled = false;
          }
        });
        if (this.disableAll) {
          this.enableAllFields();
        }
      } else {
        this.roles.map((role) => {
          role.disabled = false;
        });
      }
    }
  }

  reportToRole() {
    // let modelType = this.model;
    // if (this.model === ModelsEnum.profile) {
    //   if (
    //     this.data.record.roles.includes(RolesEnum.VLAdmin) ||
    //     this.data.record.roles.includes(RolesEnum.VLMatchMaker) ||
    //     this.data.record.roles.includes(RolesEnum.VLPathologist) ||
    //     this.data.record.roles.includes(RolesEnum.VLTechnician) ||
    //     this.data.record.roles.includes(RolesEnum.VLSupervisor) ||
    //     this.data.pathologistLabType
    //   ) {
    //     modelType = ModelsEnum.VLUser;
    //   }
    // }
    this.rolesService.reportToRole(this.data.pathologistLabType).subscribe(
      (data) => {
        this.positionList = data as [];
        if (
          this.positionList.length &&
          this.positionList.find((item) => item.key === RolesEnum.Pathologist)
        ) {
          this.formGroup.controls.position.setValue(
            this.positionList.find((item) => item.key === RolesEnum.Pathologist)
              .key
          );
          this.getUsersByPosition(true);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getCountries() {
    this.lookupsService.getCountries().subscribe(
      (data) => {
        this.countryList = data as [];
        if (!this.userId) {
          this.regionList = [];
          this.cityList = [];
          // this.formGroup.controls.country.setValue(this.countryList.find(item => item.isDefault).id);
          this.getRegions();
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getIdTypes() {
    this.lookupsService.getIdTypes().subscribe(
      (data) => {
        this.idTypesList = data as [];
        if (this.data && this.data.record) {
          this.userId = this.data.userId;
          this.selectedIdentityType(this.data.record.identificationType);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }
  selectedIdentityType(identificationType) {
    const index = this.idTypesList.find(
      (element: any) => element.code === identificationType?.code
    );
    this.formGroup.controls.identityType.setValue(index);
  }

  getRegions(changed = false) {
    this.regionLoading = this.bindLoading ? true : false;
    this.regionInputLoading = true;
    this.lookupsService
      .getRegions(this.formGroup.controls.country.value)
      .subscribe(
        (data) => {
          this.regionList = data as [];
          if (changed) {
            this.cityList = [];
            this.formGroup.controls.region.setValue(null);
            this.formGroup.controls.cityId.setValue(null);
          }
          this.regionLoading = false;
          this.regionInputLoading = false;
        },
        (err) => {
          this.regionLoading = false;
          this.regionInputLoading = false;
          console.error(err);
        }
      );
    if (this.model === ModelsEnum.ScanningCenter) {
      this.getSCCoverageAreas();
    }
  }

  getCities(changed = false) {
    this.citiesLoading = this.bindLoading ? true : false;
    this.citiesInputLoading = true;
    this.lookupsService
      .getCities(
        this.formGroup.controls.country.value,
        this.formGroup.controls.region.value
      )
      .subscribe(
        (data) => {
          this.cityList = data as [];
          if (changed) {
            this.formGroup.controls.cityId.setValue(null);
          }
          this.citiesLoading = false;
          this.citiesInputLoading = false;
        },
        (err) => {
          this.citiesLoading = false;
          this.citiesInputLoading = false;
          console.error(err);
        }
      );
  }

  checkChaneLabReviwer(record = this.data.record) {
    if (this.userId && record.lab.id !== this.formGroup.controls.lab.value) {
      const query = {
        UserId: this.userId,
        UserType: this.data.record.userType,
      };
      this.service.checkBeforeDeleteUser(query).subscribe(
        (res: any) => {
          if (res.roles && res.roles.length) {
            this.swalService
              .swalConfirmation(
                this.translate.instant('swalMsg.yesUpdateHisData'),
                this.translate.instant('This User has reviewer role'),
                this.translate.instant('swalMsg.areYouSureToUpdateHisData')
              )
              .then((result) => {
                if (result.value) {
                  this.getUsersByPosition();
                } else {
                  if (record.lab) {
                    this.formGroup.controls.lab.setValue(record.lab.id);
                  }
                }
              });
          }
        },
        (err) => {
          if (record.lab) {
            this.formGroup.controls.lab.setValue(record.lab.id);
          }
        }
      );
    } else {
      this.getUsersByPosition();
    }
  }

  getUsersByPosition(event = null) {
    const filter: any = {};
    filter.RoleName = this.formGroup.controls.position.value;
    filter.LabType = this.data.pathologistLabType;
    if (this.data.labId && this.data.pathologistLabType === 'LAB') {
      filter.LabId = this.data.labId;
      this.formGroup.controls.lab.setValue(this.data.labId);
    }
    if (this.userId && this.data.record.id) {
      filter.UserID = this.data.record.id;
    }
    // if (this.model === ModelsEnum.LabUser || this.model === ModelsEnum.Lab) {
    //   filter.labId = this.formGroup.controls.lab.value;
    // }
    // if (
    //   this.userId &&
    //   (
    //     this.model === ModelsEnum.LabUser ||
    //     this.model === ModelsEnum.Lab ||
    //     this.model === ModelsEnum.VLUser
    //   )
    // ) {
    //   filter.UserID = this.userId;
    // }
    this.reviewerList = [];
    this.service.getReportToUsers(filter).subscribe(
      (data) => {
        this.reviewerList = data as [];
        if (!event) {
          this.formGroup.controls.reviewer.setValue(null);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getCoverageAreas() {
    const filter = { CountryId: this.formGroup.controls.country.value };
    this.citiesTree = [];
    this.lookupsService.getCoverageAreas(filter).subscribe(
      (data) => {
        this.citiesTree = data as [];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getSCCoverageAreas(OurSC = false) {
    this.loadingCA = this.bindLoading ? true : false;
    this.loadingCAInput = true;
    const filter = {
      ScanningCenterId: OurSC
        ? 0
        : this.formGroup.controls.scanningCenterId.value,
    };
    this.citiesTree = [];
    this.service.getSCCoverageAreas(filter).subscribe(
      (data) => {
        this.citiesTree = data as [];
        this.loadingCA = false;
        this.loadingCAInput = false;
      },
      (err) => {
        this.loadingCA = false;
        this.loadingCAInput = false;
        console.error(err);
      }
    );
  }

  bindDataToUpdate(record = this.data.record) {
    this.bindLoading = true;
    if (!this.disableAll) {
      if (record.lab) {
        this.formGroup.controls.lab.setValue(record.lab.id);
        this.formGroup.controls.hospitalId.setValue(record.lab.id);
        this.formGroup.controls.clinicId.setValue(record.lab.id);
      }
      if (record.scanningCenter) {
        this.formGroup.controls.scanningCenterId.setValue(
          record.scanningCenter.id
        );
        this.getSCCoverageAreas();
      }
      this.formGroup.controls.contactNumber.setValue(record.contactNumber);
      this.selectedCountryISO = record.contactNumberFlag;
      if (this.data.record?.lab?.length) {
        this.formGroup.controls.canSendToSO.setValue(
          record.lab[0].canSendScondOpinion
        );
        this.formGroup.controls.HasReviewer.setValue(record.lab[0].hasReviewer);
      }
      this.formGroup.controls.role.setValue(record.roles);
      this.checkRoles(null);

      if (record.coverageAreas && record.coverageAreas.length > 0) {
        if (record.userType === ModelsEnum.VLUser) {
          if (record.coverageAreas.length) {
            this.coveredAreaList = [];
            this.activeAreaIndex = undefined;
            record.coverageAreas.forEach((element) => {
              this.coveredAreaList.push({
                country: { id: element.id, name: element.name },
                cities: element.childs,
                selected: [],
              });
            });
          }
        } else {
          this.detailsSelectedCities = record.coverageAreas;
        }
      }
    }

    if (
      this.data.record?.lab?.length &&
      this.data.record?.lab[0]?.hasReviewer
    ) {
      this.formGroup.controls.position.setValue(
        this.data.record.lab[0]?.reviwer?.reviewerRole
      );
      this.getUsersByPosition(true);
      this.formGroup.controls.reviewer.setValue(
        Number(this.data.record.lab[0]?.reviwer?.reviewerId)
      );
    }

    this.formGroup.controls.contactNumber.setValue(record.contactNumber);
    this.selectedCountryISO = record.contactNumberFlag;
    this.selectedIdentityType(record.identificationType);
    this.formGroup.controls.identityId.setValue(record.identityNumber);
    this.formGroup.controls.scfhs.setValue(record.scfhs);
    this.formGroup.controls.OtherIdentityType.setValue(
      record.otherIdentityType
    );

    this.formGroup.controls.firstName.setValue(record.firstName);
    this.formGroup.controls.lastName.setValue(record.lastName);

    this.formGroup.controls.email.setValue(record.email);

    this.formGroup.controls.canShareToConCural.setValue(
      record.canShareToConCural
    );
    if (record.canShareToConCural) {
      this.formGroup.controls.conCuralUserName.setValue(
        record.conCuralUserName
      );
      this.formGroup.controls.conCuralPassword.setValue(
        record.conCuralPassword
      );
    }
    this.formGroup.controls.country.setValue(record.country?.id);
    this.formGroup.controls.region.setValue(record.region?.id);
    this.formGroup.controls.cityId.setValue(record.city?.id);
    this.formGroup.controls.address.setValue(record.address);
    if (record.subSpeciality && record.subSpeciality.length > 0) {
      const selectedsub = [];
      record.subSpeciality.forEach((oldSub) => {
        this.subspecialtieList.forEach((mainSub) => {
          if (oldSub.id === mainSub.id) {
            selectedsub.push(mainSub);
          }
        });
      });
      this.formGroup.controls.subspecialties.setValue(selectedsub);
    }

    if (record.availability && record.availability.length > 0) {
      record.availability.map((element) => {
        const index = this.availabilityList.findIndex(
          (item) => item.availableDay === element.availableDay
        );
        if (index > -1) {
          this.availabilityList[index].availableDay = element.availableDay;
          this.availabilityList[index].availableTimeFrom =
            element.availableTimeFrom;
          this.availabilityList[index].availableTimeTo =
            element.availableTimeTo;
          this.availabilityList[index].isActive = true;
        }
      });
    }
    this.getRegions();
    this.getCities();
    if (this.data.disableInpusForUpdate) {
      console.log(this.data.disableInpusForUpdate)
      this.disableAllFields();
      this.formGroup.controls.identityType.disable();
      this.formGroup.controls.OtherIdentityType.disable();
      this.formGroup.controls.identityId.disable();
      this.formGroup.controls.contactNumber.disable();
      this.disableAll = true;
    }
    this.bindLoading = false;
  }

  getSubspecialties() {
    this.service.getSubspecialties().subscribe(
      (data) => {
        this.subspecialtieList = data as [];
        if (data && this.userId && this.data.record) {
          this.formGroup.controls.subspecialties.setValue(
            this.bindMatMultiSelect(
              this.data.record.subSpeciality,
              this.subspecialtieList
            )
          );
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  clearCountry() {
    this.formGroup.controls.country.setValue(null);
    this.formGroup.controls.region.setValue(null);
    this.formGroup.controls.cityId.setValue(null);
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.isPathologist) {
      this.isValidTimeList = true;
      this.validateTimeList();
    }
    if (
      (this.checkRole('role', RolesEnum.SCTechnician) ||
        this.checkRole('role', RolesEnum.ScanningCenterRepresentative)) &&
      this.selectedCities.length === 0 &&
      this.checkCoverageAreas()
    ) {
      return;
    }
    if (this.isPathologist) {
      this.checkLenghtColumn();
    } else {
      this.isValidTimeList = true;
    }
    if (this.checkMultiCoveredArea()) {
      if (!this.coveredAreaList[0].country) {
        return;
      }
    }
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid && this.isValidTimeList) {
      this.loading = true;
      const data = {
        firstName: this.formGroup.controls.firstName.value,
        lastName: this.formGroup.controls.lastName.value,
        email: this.formGroup.controls.email.value,
        ContactNumberFlag:
          this.formGroup.controls.contactNumber.value.countryCode,
        ContactNumberExtension:
          this.formGroup.controls.contactNumber.value.dialCode,
        ContactNumber: this.formGroup.controls.contactNumber.value.number,
        cityId: this.formGroup.controls.cityId.value,
        address: this.formGroup.controls.address.value,
        Availability: [],
        subspecialties: [],
        cities: [],
        identityTypeId: this.formGroup.controls.identityType.value?.id,
        OtherIdentityType: this.formGroup.controls.OtherIdentityType.value,
        identityType: this.formGroup.controls.identityId.value,

        userType: null,
        entityId: null,

        labType: null,
        scfhs: this.formGroup.controls.scfhs.value,
      } as UserForm;
      if (this.isPathologist) {
        data.isTrainer = this.data.isTrainer;
      }
      data.rolesNames = this.formGroup.controls.role.value;
      data.canShareToConCural =
        this.formGroup.controls.canShareToConCural.value;
      data.conCuralUserName = this.formGroup.controls.conCuralUserName.value;
      data.conCuralPassword = this.formGroup.controls.conCuralPassword.value;
      if (this.isPathologist && this.data.pathologistLabType !== 'All') {
        data.labType = this.data.pathologistLabType;
      }
      if (
        this.model === ModelsEnum.hospitalUser ||
        this.model === ModelsEnum.Hospital
      ) {
        data.userType = 'HOSPITAL_USER';
        data.entityId = this.formGroup.controls.hospitalId.value;
      }
      if (
        this.model === ModelsEnum.clinicUser ||
        this.model === ModelsEnum.Clinic
      ) {
        data.userType = 'CLINIC_USER';
        data.entityId = this.formGroup.controls.clinicId.value;
      }
      if (this.model !== ModelsEnum.profile) {
        if (this.userId) {
          data.userId = this.userId;
        }
      }
      if (
        this.model === ModelsEnum.LabUser ||
        this.model === ModelsEnum.Lab ||
        this.data.pathologistLabType === 'LAB'
      ) {
        data.labId = this.formGroup.controls.lab.value;
      }
      if (
        this.model === ModelsEnum.SCUser ||
        this.model === ModelsEnum.ScanningCenter
      ) {
        data.scanningCenterId = this.formGroup.controls.scanningCenterId.value;
      }
      if (this.isPathologist) {
        if (this.formGroup.controls.subspecialties.value) {
          this.formGroup.controls.subspecialties.value.forEach((element) => {
            data.subspecialties.push(element.id);
          });
        }
        if (this.availabilityList) {
          this.availabilityList.map((element) => {
            if (element.isActive) {
              const obj: Availability = {
                availableDay: element.availableDay,
                availableTimeFrom: element.availableTimeFrom,
                availableTimeTo: element.availableTimeTo,
              };
              data.Availability.push(obj);
            }
          });
        }
        // if (data.Availability.length === 0) {
        //   this.isValidTimeList = false;
        // }
        data.requestSecondOpinion = this.formGroup.controls.canSendToSO.value;
        data.hasReviewer = this.formGroup.controls.HasReviewer.value;
        if (this.formGroup.controls.HasReviewer.value) {
          data.reviewerID = this.formGroup.controls.reviewer.value;
          data.reviewerRoleName = this.formGroup.controls.position.value;
        } else {
          data.reviewerID = null;
          data.reviewerRoleName = null;
        }
      }
      if (this.checkCoverageAreas()) {
        if (this.selectedCities.length > 0) {
          this.selectedCities.forEach((element) => {
            data.cities.push(element.id);
          });
        }
      }
      if (this.checkMultiCoveredArea()) {
        this.coveredAreaList.forEach((item) => {
          if (item.country && item.country.id) {
            if (item.selected && item.selected.length) {
              item.selected.forEach((element) => {
                data.cities.push(element.id);
              });
            } else {
              item.cities.forEach((element) => {
                element.childs.forEach((city) => {
                  data.cities.push(city.id);
                });
              });
            }
          }
        });
      }
      if (this.model === ModelsEnum.profile) {
        this.service.updateProfile(data).subscribe(
          () => {
            this.loading = false;
            localStorage.removeItem('concurelUser');
            this.swalService.swalSuccess(
              this.userId ? this.translate.instant('swalMsg.updatedsuccessfully') : this.translate.instant('swalMsg.savedsuccessfully'));
            this.dialogRef.close(true);
          },
          (err) => {
            this.loading = false;

            this.handleErrors(err.detail);
          }
        );
      } else {
        if (this.userId) {
          const query = {
            UserId: this.userId,
            UserType: this.data.record.userType,
          };
          this.service.checkBeforeDeleteUser(query).subscribe(
            (res: any) => {
              if (res.roles) {
                let confirmUpdate = false;
                res.roles.forEach((role) => {
                  if (!confirmUpdate) {
                    confirmUpdate = !data.rolesNames.some(
                      (item) => item === role
                    );
                  }
                });
                if (confirmUpdate) {
                  this.swalService
                    .swalConfirmation(
                      this.translate.instant('swalMsg.yesUpdateHisData'),
                      this.translate.instant('This User has reviewer role'),
                      this.translate.instant('swalMsg.areYouSureToUpdateHisData')
                    )
                    .then((result) => {
                      if (result.value) {
                        this.saveUser(data);
                      } else {
                        this.loading = false;
                      }
                    });
                } else {
                  this.saveUser(data);
                }
              }
            },
            (err) => {
              this.loading = false;
            }
          );
        } else {
          this.saveUser(data);
        }
      }
    }
  }

  saveUser(data) {
    this.service.saveUser(this.model, data).subscribe(
      () => {
        this.loading = false;
        this.swalService.swalSuccess(
          this.userId ? this.translate.instant('swalMsg.updatedsuccessfully') : this.translate.instant('swalMsg.savedsuccessfully')
        );
        this.dialogRef.close(true);
      },
      (err) => {
        this.loading = false;
        this.handleErrors(err.detail);
      }
    );
  }

  handleErrors(errors) {
    errors = JSON.parse(errors);
    errors.ErrorDTO.forEach((error) => {
      if (error.ErrorCode === 'Repeated') {
        this.formGroup.controls[error.ErrorDetail].setErrors({
          notUniqe: true,
        });
      } else if (error.ErrorCode === 'PhoneNumberExit') {
        this.formGroup.controls['contactNumber'].setErrors({ notUniqe: true });
        this.swalService.swalFailed(error.Error);
      } else if (error.ErrorCode === 'UserHaveCases') {
        this.formGroup.controls.lab.setValue(this.data.record.lab.id);
        this.swalService.swalFailed(error.Error);
      } else if (error.ErrorCode === 'ValidationError') {
        this.swalService.swalFailed(error.Error);
      }
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  checkRole(controlName: string, item: string) {
    if (this.formGroup.controls[controlName].value) {
      return this.formGroup.controls[controlName].value.includes(item);
    }
    return false;
  }

  isInArray(parentObj, item) {
    return parentObj.some((obj) => obj.id === item.id);
  }

  updateSelectedCities(data) {
    this.detailsSelectedCities = [];
    this.selectedCities = [];
    if (data) {
      data.forEach((element: Lookup) => {
        if (!element.childs && !this.isInArray(this.selectedCities, element)) {
          this.selectedCities.push(element);
        }
      });
    }
  }

  validateTimeList() {
    this.availabilityList.forEach((element) => {
      if (element.isActive && this.isValidTimeList) {
        if (!element.availableTimeFrom || !element.availableTimeTo) {
          this.isValidTimeList = false;
        } else {
          this.isValidTimeList = true;
        }
      }
    });
    return this.isValidTimeList;
  }

  changeEvent(e, index) {
    if (!this.availabilityList[index].isActive) {
      this.availabilityList[index].availableTimeFrom = '';
      this.availabilityList[index].availableTimeTo = '';
    }
    this.checkLenghtColumn();
  }

  changeTimeEvent(e, index) {
    if (
      !this.availabilityList[index].availableTimeFrom ||
      !this.availabilityList[index].availableTimeTo
    ) {
      this.isValidTimeList = false;
    } else {
      this.isValidTimeList = true;
    }
  }

  bindMatMultiSelect(data, parent) {
    const selectedList = [];
    if (parent && data) {
      parent.forEach((item) => {
        if (
          data.some((obj) => obj.id === item.id) ||
          data.some((obj) => obj.id === item.roleId)
        ) {
          selectedList.push(item);
        }
      });
    }
    return selectedList;
  }

  checkLenghtColumn() {
    this.validateTimeList();
    const index = this.availabilityList.findIndex(
      (item) => item.isActive === true
    );
    if (index < 0 && this.isPathologist) {
      this.isValidTimeList = true;
      return;
    } else if (index !== -1) {
      if (
        this.availabilityList[index].availableTimeFrom &&
        !this.availabilityList[index].availableTimeTo
      ) {
        this.isValidTimeList = false;
        return;
      }
    } else {
      this.isValidTimeList = true;
    }
  }

  /////////////////////////////////
  checkMultiCoveredArea() {
    if (
      this.model === ModelsEnum.VLUser &&
      (this.checkRole('role', 'VLTechnician') ||
        this.checkRole('role', 'VLRepresentative'))
    ) {
      return true;
    }
    return false;
  }
  ////////////////// Sets Areas
  openNewArea() {
    if (
      this.coveredAreaList[this.coveredAreaList.length - 1].country &&
      this.coveredAreaList[this.coveredAreaList.length - 1].country.name
    ) {
      this.coveredAreaList.push(new CoveredArea());
    }
    this.activeAreaIndex = this.coveredAreaList.length - 1;
  }
  toggleAreaset(index) {
    if (this.activeAreaIndex === index) {
      this.activeAreaIndex = undefined;
    } else {
      this.activeAreaIndex = index;
    }
  }
  pushNewArea(record) {
    this.coveredAreaList[this.activeAreaIndex] = record;
    this.activeAreaIndex = undefined;
  }
  deleteArea() {
    this.coveredAreaList.splice(this.activeAreaIndex, 1);
    if (!this.coveredAreaList.length) {
      this.coveredAreaList.push(new CoveredArea());
      this.activeAreaIndex = this.coveredAreaList.length - 1;
    } else {
      this.activeAreaIndex = undefined;
    }
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

  setEndTimeMin(index) {
    this.availabilityList[index].availableTimeTo = '';
    setTimeout(() => {
      const startTime =
        this.availabilityList[index].availableTimeFrom.split(':');
      let hours: any = startTime[0];
      let mins: any = startTime[1];
      if (Number(mins) + 1 > 60) {
        mins = Number(mins) + 1 - 60;
        mins = mins < 1 ? '0' + mins : mins;
        hours = Number(hours) + 1;
        hours = hours === 24 ? '00' : hours < 1 ? '0' + hours : hours;
      } else {
        mins = Number(mins) + 1;
      }
      if (mins === 60) {
        mins = '00';
        hours = Number(hours) + 1;
      }
      this.availabilityList[index].endTimeMin = hours + ':' + mins;
    }, 100);
  }

  checkUserFound() {
    // tslint:disable-next-line: max-line-length
    // ((this.formGroup.controls.identityType.value?.code == 'OT' && this.formGroup.controls.OtherIdentityType.value) || this.formGroup.controls.identityType.value?.code != 'OT'
    if (
      this.isPathologist &&
      this.data.makeSearchForPathologist &&
      ((this.formGroup.controls.identityType.value?.id &&
        this.formGroup.controls.identityId.value &&
        this.formGroup.controls.identityId.valid &&
        !this.formGroup.controls.contactNumber.value) ||
        (this.formGroup.controls.contactNumber.value &&
          this.formGroup.controls.contactNumber.valid &&
          !this.formGroup.controls.identityId.value &&
          !this.formGroup.controls.identityType.value))
    ) {
      // SearchForPathologiest
      const data = {} as any;
      if (this.formGroup.controls.identityType.value) {
        data.identityId = this.formGroup.controls.identityType.value.id;
      }
      if (this.formGroup.controls.identityId.value) {
        data.IdentityNumber = this.formGroup.controls.identityId.value;
      }
      if (this.formGroup.controls.contactNumber.value) {
        data.ContactNumber =
          this.formGroup.controls.contactNumber.value?.number;
      }

      if (
        this.formGroup.controls.identityType.value?.code === 'OT' &&
        this.formGroup.controls.OtherIdentityType.value
      ) {
        data.OtherIdentityType =
          this.formGroup.controls.OtherIdentityType.value;
      }
      this.service.searchForPathologiest(data).subscribe((res: UserDetails) => {
        if (res) {
          this.disableAll = true;
          this.disableAllFields();
          // this.bindDataToAdd(res);
          this.bindDataToUpdate(res);
        } else {
          if (this.disableAll === true) {
            this.clearAllFields();
          }
          this.disableAll = false;
          this.enableAllFields();
        }
      });
    } else {
      // this.enableAllFields();
      // this.clearAllFields();
      // this.disableAll = false;
    }
  }
}
