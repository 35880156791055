import { Component, OnInit, NgZone } from '@angular/core';
import { TranslationService } from './services/translation.service';
import { TenantService } from './services/tenant.service';
import * as accessibility from 'accessibility';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslationService,
    private tenantService: TenantService
  ) {
    this.getTenant();
  }

  ngOnInit() {
    this.setLnag();
    let labels = {
      resetTitle: 'reset (in my language)',
      closeTitle: 'close (in my language)',
      menuTitle: 'title (in my language)',
      increaseText: 'increase text size (in my language)',
      decreaseText: 'decrease text size (in my language)',
      increaseTextSpacing: 'increase text spacing (in my language)',
      decreaseTextSpacing: 'decrease text spacing (in my language)',
      increaseLineHeight: 'increase line height (in my language)',
      decreaseLineHeight: 'decrease line height (in my language)',
      invertColors: 'invert colors (in my language)',
      grayHues: 'gray hues (in my language)',
      underlineLinks: 'underline links (in my language)',
      bigCursor: 'big cursor (in my language)',
      readingGuide: 'reading guide (in my language)',
      textToSpeech: 'text to speech (in my language)',
      speechToText: 'speech to text (in my language)',
      disableAnimations: 'Disable Animations',
      hotkeyPrefix: 'Hotkey Prefix',
    };
    let modules: accessibility.IAccessibilityModulesOptions = {
      increaseText: true,
      decreaseText: true,
      increaseTextSpacing: true,
      decreaseTextSpacing: true,
      increaseLineHeight: true,
      decreaseLineHeight: true,
      invertColors: true,
      grayHues: true,
      bigCursor: true,
      readingGuide: true,
      underlineLinks: true,
      textToSpeech: true,
      speechToText: true,
      disableAnimations: true,
    };
    let options: accessibility.IAccessibilityOptions = {
      labels: labels,
      modules: modules,
    };
    options.textToSpeechLang = 'en-US'; // or any other language
    options.speechToTextLang = 'en-US'; // or any other language
    new accessibility.Accessibility(options);
    console.log(new accessibility.Accessibility(options));
  }

  setLnag() {
    const lang = this.translateService.getSelectedLanguage();
    this.translateService.setLanguage(lang);
  }

  getTenant() {
    this.tenantService.getTenant().subscribe(
      (data: any) => {
        this.tenantService.defaultTenantSubject.next(data);
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
